@use 'functions';
@use 'mixins';
@use 'variables';
@use 'sass:map';

// Headlines
// -----------------------------
h1:not(.gdds-headline),
.h1:not(.gdds-headline) {
  @include mixins.word-wrap();
  font-size: 2rem;
  font-weight: var(--h1-weight);
  letter-spacing: functions.calc-letter-spacing(5);
  line-height: 2rem;
  margin: 0 0 3.125rem;
  text-transform: var(--hd-transform);

  .gdds & {
    margin: 0 0 0.625rem;

    &.gdds-element {
      margin-top: 1.25rem;
    }

    @include mixins.breakpoint('medium') {
      margin: 0 0 2rem;
    }
  }

  .nord &,
  .kolo &,
  .twyford & {
    @include mixins.screen-xs {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    @include mixins.screen-sm {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }

    @include mixins.screen-md {
      font-size: 2.625rem;
      line-height: 3.25rem;
    }

    @include mixins.screen-lg {
      font-size: 2.875rem;
      line-height: 3.75rem;
    }
  }

  .kolo & {
    span {
      font-weight: var(--h1-weight);
    }
  }

  @include mixins.breakpoint(medium) {
    font-size: 2.25rem;
    line-height: 1;
  }

  @include mixins.breakpoint(large) {
    font-size: 2.875rem;
    line-height: 1;
    margin: 0 0 4.375rem;
  }

  // variants
  // less margin bottom if introtext follows
  .has-introtext & {
    margin-bottom: 1rem;
  }
}

h2:not(.gdds-headline):not(.gigya-composite-control-header),
.h2:not(.gdds-headline) {
  @include mixins.word-wrap();
  font-size: 1.75rem;
  font-weight: var(--h2-weight);
  letter-spacing: functions.calc-letter-spacing(20);
  line-height: 1.75rem;
  margin: 0 0 2.5em;
  text-transform: var(--hd-transform);

  .gdds & {
    margin: 0 0 0.625rem;

    @include mixins.breakpoint(medium) {
      margin: 0 0 1.5rem;
    }
  }

  .nord &,
  .kolo &,
  .twyford & {
    @include mixins.screen-xs {
      font-size: 1.625rem;
      line-height: 2rem;
    }

    @include mixins.screen-sm {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    @include mixins.screen-md {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    @include mixins.screen-lg {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }
  }

  .kolo & {
    span {
      font-weight: var(--h2-weight);
    }
  }

  @include mixins.breakpoint(medium) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }

  @include mixins.breakpoint(large) {
    font-size: 2.125rem;
    line-height: 2.375rem;
    margin: 0 0 3rem;
  }
}

h3:not(.gdds-headline),
.h3:not(.gdds-headline) {
  @include mixins.word-wrap();
  font-size: 1.5rem;
  font-weight: var(--h3-weight);
  letter-spacing: functions.calc-letter-spacing(30);
  line-height: 1.625rem;
  margin: 0 0 1.625rem;
  text-transform: var(--hd-transform);

  .gdds & {
    text-transform: initial;
    margin: 0 0 0.625rem;

    @include mixins.breakpoint(medium) {
      margin: 0 0 1.5rem;
    }
  }

  .nord &,
  .kolo &,
  .twyford & {
    @include mixins.screen-xs {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @include mixins.screen-sm {
      font-size: 1.625rem;
      line-height: 2rem;
    }

    @include mixins.screen-md {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    @include mixins.screen-lg {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .kolo & {
    span {
      font-weight: var(--h3-weight);
    }
  }

  @include mixins.breakpoint(medium) {
    font-size: 1.5rem;
    letter-spacing: functions.calc-letter-spacing(20);
    line-height: 1.625rem;
  }

  @include mixins.breakpoint(large) {
    font-size: 1.625rem;
    line-height: 2rem;
    margin: 0 0 1.625rem;
  }

  & + ul,
  & + ol,
  & + p {
    margin-bottom: 3.4375rem;
    margin-top: 0;
  }
}

h4:not(.gdds-headline),
.h4:not(.gdds-headline) {
  @include mixins.word-wrap();
  font-size: 1.125rem;
  font-weight: var(--h4-weight);
  letter-spacing: functions.calc-letter-spacing(20);
  line-height: 1.375rem;
  margin: 0 0 1.9375rem;
  text-transform: var(--hd-transform);

  .gdds & {
    text-transform: initial;
    margin: 0 0 1.25rem;

    @include mixins.breakpoint(medium) {
      margin: 0 0 1.5rem;
    }
  }

  .nord &,
  .kolo &,
  .twyford & {
    @include mixins.screen-xs {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @include mixins.screen-sm {
      font-size: 1.625rem;
      line-height: 2rem;
    }

    @include mixins.screen-md {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    @include mixins.screen-lg {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .kolo & {
    span {
      font-weight: var(--h4-weight);
    }
  }

  @include mixins.breakpoint(medium) {
    margin: 0 0 2.1875rem;
  }

  @include mixins.breakpoint(large) {
    margin: 0 0 1.9375rem;
  }

  & + ul,
  & + ol,
  & + p {
    margin-bottom: 1.875rem;
    margin-top: 1.5625rem;
  }
}

h5:not(.gdds-headline),
.h5:not(.gdds-headline) {
  @include mixins.word-wrap();
  font-size: 1rem;
  font-weight: var(--h5-weight);
  line-height: 1.25rem;
  margin: 0 0 1.25rem;
  text-transform: var(--hd-transform);

  .gdds & {
    text-transform: initial;
  }

  .nord &,
  .kolo &,
  .twyford & {
    @include mixins.screen-xs {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @include mixins.screen-sm {
      font-size: 1.375rem;
      line-height: 1.75rem;
    }

    @include mixins.screen-md {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .kolo & {
    span {
      font-weight: var(--h5-weight);
    }
  }

  @include mixins.breakpoint(large) {
    font-size: 0.9375rem;
    line-height: 1.5rem;
    margin: 0 0 1.9375rem;
  }
}

h6:not(.gdds-headline),
.h6:not(.gdds-headline) {
  @include mixins.word-wrap();
  font-size: 1rem;
  font-weight: var(--h6-weight);
  line-height: 1.375rem;
  margin: 0 0 0.875rem;
  text-transform: var(--hd-transform);

  .gdds & {
    text-transform: initial;
  }

  .nord &,
  .kolo &,
  .twyford & {
    @include mixins.screen-xs {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    @include mixins.screen-sm {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    @include mixins.screen-md {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  .kolo & {
    span {
      font-weight: var(--h6-weight);
    }
  }

  @include mixins.breakpoint(large) {
    font-size: 0.8125rem;
    line-height: 1.3125rem;
  }

  & + ul,
  & + ol,
  & + p {
    margin-bottom: 1.875rem;
    margin-top: 0.75rem;
  }
}

.gdds {
  sub,
  sup {
    font-size: 0.75rem;
  }
}

b,
strong {
  font-weight: bolder;
}

.c-page-headline {
  padding-top: variables.$spacing-xl;
}

// Headline for Stage
.stage--hompage {
  font-size: 2rem;
  font-weight: variables.$font-weight-light;
  letter-spacing: functions.calc-letter-spacing(5);
  line-height: 2rem;

  @include mixins.breakpoint(medium) {
    font-size: 2.875rem;
    line-height: 2.875rem;
  }

  @include mixins.breakpoint(large) {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }

  b {
    font-weight: variables.$font-weight-bold;
  }
}

// Break line if bold tag is inside headline
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  .title--block & {
    b {
      display: block;
    }
  }

  b {
    font-weight: variables.$font-weight-bold;
  }
}

// Headlines in combination with other elements

.separate-title {
  & > h4:not(.h3),
  & > h5 {
    font-weight: variables.$font-weight-regular;

    &.bold {
      font-weight: variables.$font-weight-bold;
    }
  }
}

.default {
  h1:not(.gdds-headline),
  h2:not(.gdds-headline),
  h3:not(.gdds-headline),
  .h1:not(.gdds-headline),
  .h2:not(.gdds-headline),
  .h3:not(.gdds-headline) {
    line-height: 1.12;
  }
}

// Paragraph
// -----------------------------
p {
  @include mixins.word-wrap();
  hyphens: inherit;
  max-width: 48.4375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: variables.$font-weight-regular;
  margin-bottom: 1.875rem;

  @include mixins.breakpoint(large) {
    font-size: 0.9375rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  b {
    font-weight: variables.$font-weight-bold;
  }

  a {
    display: inline;
    left: 0;

    &::before {
      position: relative;
      text-indent: 0;
      top: 0;
      padding-right: 5px;
    }

    &.noArrow.gdds-element {
      span {
        font-weight: variables.$font-weight-bold;
      }
    }
  }
}

.nord,
.kolo,
.twyford {
  p {
    @include mixins.breakpoint(large) {
      font-size: 1rem;
    }
  }
}

// Lists
// -----------------------------
ol,
ul {
  margin: 0 0 0.9375rem;
  padding: 0 0 0 1.0625rem;

  li b {
    font-weight: variables.$font-weight-bold;
  }
}

ol {
  font-size: 1rem;
  font-weight: variables.$font-weight-regular;
  line-height: 1.5rem;
  list-style-type: none;
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
  padding-left: 0;

  @include mixins.breakpoint(large) {
    font-size: 0.9375rem;
  }

  & > li {
    @include mixins.word-wrap();
    counter-increment: listcounter;
    padding-bottom: 0.625rem;
    padding-left: 1.25rem;
    position: relative;

    &::before {
      content: counter(listcounter) '.';
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:first-child {
      counter-reset: listcounter;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    & > ol {
      margin-bottom: 0;
    }

    & > ul {
      margin-top: 0.625rem;
      margin-bottom: 0;

      li {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }
}

ul {
  font-size: 1rem;
  font-weight: variables.$font-weight-regular;
  line-height: 1.5rem;
  list-style-type: disc;
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;

  @include mixins.breakpoint(large) {
    font-size: 0.9375rem;
  }

  @include mixins.breakpoint(large) {
    font-size: 0.9375rem;
  }

  li {
    @include mixins.word-wrap();
    padding-bottom: 0.625rem;

    &:last-of-type {
      padding-bottom: 0;
    }

    & > ul {
      margin: 0.625rem 0 0 0;
    }

    & > ol {
      margin-top: 0.625rem;
      margin-bottom: 0;
    }
  }
}

ul li > ul,
ol li > ol {
  margin-top: 0.625rem;
}

p,
ol,
ul {
  a,
  a:visited {
    &::before {
      padding: 0 9px 0 0;
    }
  }
}

// Anchor
// -----------------------------
a,
a:visited {
  @include mixins.word-wrap();
  color: var(--gb-brand-primary-light);
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration: none;
  transition: color 0.3s;
  display: inline-block;
  position: relative;

  @include mixins.breakpoint(large) {
    font-size: 0.9375rem;
  }

  &.gdds-element {
    font-size: unset;
  }

  &::before {
    @include mixins.web20-icon('arrow-link-light-right');
    font-size: 0.6875rem;
    padding: 0 9px 0 0;
    position: relative;
    top: -1px;
  }

  &.noArrow::before {
    content: none;
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
    outline: none;

    &::before {
      text-decoration: none;
    }
  }
}

.xy a:not(.call-to-action-button):not(.oxomi-content-icon):not(.gigya-screen-dialog-close a):not(.gigya-composite-control-link),
.gdds a:not(.call-to-action-button):not(.oxomi-content-icon):not(.gigya-screen-dialog-close a):not(.gigya-composite-control-link) {
  &.gdds-element {
    &.noArrow,
    &.arrow {
      min-width: initial;
    }

    &.noArrow {
      > span:first-child {
        padding-right: 0;
      }

      &.isNordicsStyle {
        font-weight: bold;
      }
    }

    &.arrow {
      &:active {
        svg {
          fill: variables.$gb-white;
        }

        span {
          color: variables.$gb-white;
        }
      }

      span {
        color: var(--gb-brand-primary-light);

        svg {
          fill: var(--gb-brand-primary-light);
        }
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &::before {
    content: none !important;
  }
}

li:focus {
  outline: none;
}

// Links with arrows
.link-arrow {
  color: var(--gb-brand-primary-light);
  display: inline-block;
  padding-left: 1.25rem;
  position: relative;
  text-decoration: none;
  transition: color 0.3s;

  &::before {
    padding: 0;
    position: absolute;
    top: 5px;
    left: 0;
  }

  &:hover {
    &::before {
      text-decoration: none;
    }
  }
}

// Links with arrows in Lists
.link-list {
  list-style-type: none;
  padding-left: 0;

  li {
    padding-bottom: 0.625rem;

    &::before {
      content: none;
    }

    &:focus {
      outline: none;
    }

    a,
    button {
      display: inline-block;
      margin-bottom: 0;
      left: 1.25rem;

      &::before {
        position: relative;
        padding: 0;
        text-indent: -1.25rem;
      }
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

// Button
// -----------------------------
button {
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
}

// Forms
// -----------------------------
input {
  cursor: text;

  &:active,
  &:focus {
    outline: none;
  }
}