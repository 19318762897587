@use "../base/variables";

.c-pagination {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;

  .pagination-left {
    a {
      color: var(--gb-brand-primary);
      float: left;
      padding: 8px 1rem;
      text-decoration: none;
      transition: background-color 0.3s;
      font-weight: variables.$font-weight-bold;

      &::before {
        display: none;
      }

      &.active {
        color: variables.$gb-black;
      }

      &:hover:not(.active) {
        color: variables.$gb-black;
      }

      &.left,
      &.right {
        height: 2.375rem;
        line-height: 1;
        padding: 10px;
        width: 2.375rem;

        .icon::before {
          color: variables.$gb-white;
        }
      }

      &.left {
        background-color: variables.$gb_grey_100;
      }

      &.right {
        background-color: variables.$gb_grey-blue;
      }
    }
  }

  .pagination-right {
    color: variables.$gb_grey-blue;
    display: flex;
    font-size: 12px;

    span {
      padding: 10px 0;

      &:last-of-type {
        padding-right: 10px;

        &::after {
          display: none;
        }
      }
    }
  }

  &__trigger {
    align-items: center;
    background: none;
    border: 0;
    color: var(--gb-brand-primary);
    display: inline-flex;
    font-size: 12px;
    font-weight: variables.$font-weight-bold;
    height: 100%;
    justify-content: center;
    line-height: 1;
    margin-right: 0.625rem;
    padding: 0 0.625rem 0;

    &::after {
      color: var(--gb-brand-primary-light);
      font-size: 6px;
      right: 0;
      top: calc(50% - 3px);
    }
  }

  &__dropdown-content {
    ul li {
      cursor: pointer;
      text-align: center;
    }
  }
}
