@use 'styles/base/mixins';
@use 'styles/base/variables';

.my-list__wrapper {
  position: relative;
  margin-bottom: 5.625rem;

  @include mixins.breakpoint(large) {
    display: flex;
    flex-direction: column;
  }

  .my-list__row {
    border-bottom: variables.$border-solid-background;
    padding: 1.5625rem 0;

    @include mixins.breakpoint(large) {
      padding: 0;
    }

    &.is--disabled {
      background-color: variables.$gb_grey_060;
    }

    &.my-list__row-head {
      display: none;

      @include mixins.breakpoint(large) {
        border-top: 0;
        display: inline-flex;
      }

      .my-list__image,
      .my-list__article-number,
      .my-list__product,
      .my-list__action-amount,
      .my-list__action-delete {
        font-size: 0.8125rem;
        font-weight: variables.$font-weight-bold;

        .xy & {
          @include mixins.typography-body-2();
        }
      }

      .my-list__action-delete {
        white-space: nowrap;
      }
    }
  }

  .my-list__image,
  .my-list__article-number,
  .my-list__product,
  .my-list__action-amount,
  .my-list__action-delete {
    @include mixins.breakpoint(large) {
      border-right: 1px solid variables.$gb_grey_150;
      padding: 10px 1.875rem;
    }

    &:last-of-type {
      border-right: 0;
    }
  }

  .my-list__image {
    order: 1;
    padding-right: 0.9375rem;

    @include mixins.breakpoint(large) {
      max-height: 5rem;
      order: 0;
      text-align: center;
    }

    .image--aspect-ratio.one-one {
      @include mixins.breakpoint(large) {
        margin: 0 auto;
        width: 44%;
      }
    }
  }

  .my-list__article-number,
  .my-list__product,
  .my-list__action-amount,
  .my-list__action-delete {
    @include mixins.breakpoint(large) {
      align-items: center;
      display: flex;
    }
  }

  // Wrapper for "__action-amount" and "__action_delete" on mobile viewport
  .my-list__actions {
    order: 2;
  }

  .my-list__action-amount {
    margin-bottom: 10px;

    @include mixins.breakpoint(large) {
      margin-bottom: 0;
    }
  }

  .my-list__action-delete {
    button {
      &:not(.my-list__action-delete-gdds-button) {
        width: 100%;

        @include mixins.breakpoint(large) {
          &:hover {
            background-color: variables.$gb_white;
            color: variables.$gb_black;
          }
        }
      }
    }

    .icon::before {
      font-size: 1.5rem;
    }

    @include mixins.breakpoint(large) {
      white-space: nowrap;
    }
  }

  .my-list__action-amount,
  .my-list__action-delete {
    @include mixins.breakpoint(large) {
      @include mixins.word-wrap();
      font-weight: variables.$font-weight-bold;
    }
  }

  .my-list__article-number {
    margin-top: 1.5625rem;
    order: 3;

    @include mixins.breakpoint(large) {
      margin-top: 0;
      order: 0;
    }

    a {
      font-size: 0.8125rem;
      text-decoration: none;

      .xy & {
        @include mixins.typography-label-2();
      }

      &::before {
        display: none;
      }
    }
  }

  .my-list__product {
    color: variables.$gb_black;
    font-size: 0.8125rem;
    order: 4;

    .xy & {
      @include mixins.typography-label-2();
    }

    @include mixins.breakpoint(large) {
      order: 0;
    }
  }

  .my-list__footer {
    background-color: variables.$gb_grey_050;
    padding: 1.25rem 1.875rem 1.25rem 1.875rem;
    position: relative;

    .my-list__footer-gap {
      gap: 1rem;
    }

    form {
      margin-bottom: 0;
    }

    .my-list__footer__controls {
      align-items: center;
      display: flex;
    }

    // Print and clear list buttons
    .my-list__controls {
      @include mixins.breakpoint(large) {
        display: flex;
      }

      &__print {
        display: none;
        @include mixins.breakpoint(large) {
          display: block;
        }
      }

      button {
        font-size: 0.9375rem;
        margin: 0 1.5625rem 0.625rem 0;

        i::before {
          font-size: 0.9375rem;
        }

        &:last-child {
          margin-right: 0;
        }

        &[disabled] {
          color: variables.$gb_grey_600;
        }

        @include mixins.breakpoint(large) {
          margin-bottom: 0;
        }
      }
    }

    .my-list__buttons {
      button {
        font-size: 0.75rem;
        margin-top: 1.25rem;
        text-align: left;
        width: 100%;

        @include mixins.breakpoint(large) {
          margin-top: 0;
          margin-left: 1.25rem;
          width: inherit;
        }

        &:first-of-type {
          margin-left: 0;
        }

        .icon::before {
          font-size: 0.9375rem;
          padding-right: 0;
        }
      }
    }

    .my-list__input {
      form {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .input-group {
        display: flex;
        position: relative;

        &::after {
          @include mixins.web20-icon('pencil-filled');
          color: variables.$gb_grey_400;
          font-size: 1.5rem;
          line-height: 2;
          margin-left: 10px;
        }
      }

      .my-list__title {
        color: var(--gb-brand-primary-light);
        font-size: 2.125rem;

        &-info {
          font-size: 0.8125rem;
        }
      }

      input {
        background: none;
        border: 0 none;
        padding: 0 0 0 5px;

        @include mixins.breakpoint(medium) {
          width: inherit;
        }

        &:hover {
          border: 0 none;
          box-shadow: none;
        }

        &:focus {
          background: variables.$gb_white;
          box-shadow: none;
        }
      }
    }

    .my-list__footer-bottom {
      @include mixins.breakpoint(large) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    .my-list__title-info {
      margin-bottom: 1rem;

      @include mixins.breakpoint(large) {
        flex: 1;
        min-width: 15%;
        padding-right: 2rem;
      }

      span {
        font-size: 0.8125rem;
      }
    }
  }
}

// Print styles
// -------------------------
.my-list--print {
  display: none;
}

@media print {
  .my-list--not-print {
    display: none;
  }

  .my-list--print {
    display: block;
  }

  .my-list__wrapper {
    .my-list__button-left,
    .my-list__button-right,
    .my-list__action-delete,
    .my-list__footer {
      display: none;
    }

    .my-list__image {
      width: 15%;
    }

    .my-list__article-number {
      width: 22%;

      a {
        color: variables.$gb_black;
      }
    }

    .my-list__product {
      width: 45%;
    }

    .my-list__action-amount {
      border-right: 0;
      width: 18%;
    }

    .my-list__amount input[type='text'] {
      border: 0;
    }

    .my-list__row.is--disabled {
      background: transparent;
    }

    .my-list__title-info {
      display: block;
      position: absolute;
      top: 0;
    }
  }
}
