@use "../base/mixins";
@use "../base/variables";

.gb-select {
  display: block;
  position: relative;
  margin-bottom: 1.25rem;

  &:hover {
    text-decoration: none;
  }

  &::before {
    display: none;
  }

  &::after {
    @include mixins.web20-icon('chevron-large-down');
    pointer-events: none;
    position: absolute;
    top: 1.0625rem;
    right: 1.375rem;
  }

  &__value {
    background: variables.$gb_grey_055;
    padding: 0.6875rem 3.125rem 0.6875rem 1.375rem;
    border: 0.0625rem solid variables.$gb_grey_200;
    color: variables.$gb_black;
    text-align: left;
    text-decoration: none;
  }

  select {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
    display: block;
    cursor: pointer;
  }
}
