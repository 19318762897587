@use '../base/functions';
@use '../base/variables';
@use '../base/mixins';

.dummy-header-container {
  @include mixins.breakpoint(large) {
    padding-bottom: 3.75rem;
  }

  .dummy-header-area {
    width: 100%;
    background: variables.$gb_grey_025;
    box-shadow: 0 0.5rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.22);
    position: fixed;
    top: 0;
    z-index: variables.$z-sticky-header;
  }

  .grid-container {
    min-height: 3.75rem;
    height: 3.75rem;
  }

  .logo {
    padding-top: functions.calc-center(60, 20);
  }

  a {
    &:before {
      content: none;
    }

    img {
      width: auto;
      height: 1rem;

      @include mixins.breakpoint(large) {
        height: 1.25rem;
      }
    }
  }
}
