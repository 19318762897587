@use "mixins";
@use "variables";

// Global Variables
// -----------------------------
:root {
  --gb-brand-primary: #{variables.$platform-primary};
  --gb-brand-primary-light: #{variables.$platform-primary-light};
  --gb-brand-primary-dark: #{variables.$platform-primary-dark};
  --gb-brand-secondary-emerald: #{variables.$platform-primary-light};
  --product-tiles-new-flag: #{variables.$platform-primary-light};

  // general Colors
  --white: #{variables.$gb_white};
  --white-00: #{variables.$gb_white_00};
  --white-40: #{variables.$gb_white_40};
  --white-70: #{variables.$gb_white_70};
  --white-80: #{variables.$gb_white_80};
  --black: #{variables.$gb_black};
  --black-00: #{variables.$gb_black_00};
  --black-08: #{variables.$gb_black_08};
  --black-26: #{variables.$gb_black_26};
  --black-70: #{variables.$gb_black_70};
  --black-80: #{variables.$gb_black_80};
  --black-82: #{variables.$gb_black_82};
  --cc-actions: #{variables.$gb_cc_actions};
  --gdds_cl15: #{variables.$gb_gdds_cl15};

  // nordics full Colors also used by kolo
  --storm: #{variables.$storm};
  --wave: #{variables.$wave};
  --sky: #{variables.$sky};
  --norway: #{variables.$norway};
  --cliff: #{variables.$cliff};
  --stone: #{variables.$stone};
  --porcelain: #{variables.$porcelain};
  --earth-clay: #{variables.$earth-clay};
  --earth-sand: #{variables.$earth-sand};
  --light-teal: #{variables.$light-teal};

  // Nordic section spacing also used in kolo
  --spacing-nordics-mobile: #{variables.$spacing-nordics-mobile};
  --spacing-nordics-desktop: #{variables.$spacing-nordics-desktop};

  // nord rgba colors also used in kolo
  --color-backdrop: #{variables.$color-backdrop};
  --color-black-36: #{variables.$color-black-36};
  --color-black-06: #{variables.$color-black-06};

  // headline weights
  --h1-weight: #{variables.$font-weight-light};
  --h2-weight: #{variables.$font-weight-light};
  --h3-weight: #{variables.$font-weight-light};
  --h4-weight: #{variables.$font-weight-bold};
  --h5-weight: #{variables.$font-weight-bold};
  --h6-weight: #{variables.$font-weight-bold};

  // headline transform
  --hd-transform: #{variables.$text-transform-up};

  // gdds typography - line height - h1
  --gdds-line-height-h1-xl: #{variables.$gdds-line-height-h1-xl};
  --gdds-line-height-h1-l: #{variables.$gdds-line-height-h1-l};
  --gdds-line-height-h1-m: #{variables.$gdds-line-height-h1-m};
  --gdds-line-height-h1-s: #{variables.$gdds-line-height-h1-s};

  // gdds typography - font size - h1
  --gdds-font-size-h1-xl: #{variables.$gdds-font-size-h1-xl};
  --gdds-font-size-h1-l: #{variables.$gdds-font-size-h1-l};
  --gdds-font-size-h1-m: #{variables.$gdds-font-size-h1-m};
  --gdds-font-size-h1-s: #{variables.$gdds-font-size-h1-s};

  // spacing
  --spacing-xxxxs: #{variables.$spacing-xxxxs};
  --spacing-s: #{variables.$spacing-s};
  --spacing-m: #{variables.$spacing-m};
  --spacing-l: #{variables.$spacing-l};
  --spacing-xl: #{variables.$spacing-xl};
}

html {
  scroll-behavior: smooth;
}

.nord {
  --gb-brand-primary: #{variables.$nord-primary};
  --gb-brand-primary-light: #{variables.$nord-primary-light};
  --gb-brand-primary-dark: #{variables.$nord-primary-dark};
  --gb-brand-secondary-emerald: #{variables.$nord-secondary-emerald};
  --product-tiles-new-flag: #{variables.$nord-secondary-emerald};

  // headline weights
  --h1-weight: #{variables.$font-weight-regular};
  --h2-weight: #{variables.$font-weight-regular};
  --h3-weight: #{variables.$font-weight-regular};

  // h4-6 are bold by default

  // headline transform
  --hd-transform: #{variables.$text-transform-up};
}

.kolo {
  --gb-brand-primary: #{variables.$kolo-primary};
  --gb-brand-primary-light: #{variables.$kolo-primary-light};
  --gb-brand-primary-dark: #{variables.$kolo-primary-dark};
  --gb-brand-secondary-emerald: #{variables.$kolo-secondary-emerald};
  --product-tiles-new-flag: #{variables.$kolo-secondary-emerald};

  // headline weights
  --h1-weight: #{variables.$font-weight-bold};
  --h2-weight: #{variables.$font-weight-bold};
  --h3-weight: #{variables.$font-weight-regular};

  // h4-6 are bold by default

  // headline transform
  --hd-transform: #{variables.$text-transform-up};
}

.twyford {
  --gb-brand-primary: #{variables.$twyford-primary};
  --gb-brand-primary-light: #{variables.$twyford-primary-light};
  --gb-brand-primary-dark: #{variables.$twyford-primary-dark};
  --gb-brand-secondary-emerald: #{variables.$twyford-secondary-emerald};
  --product-tiles-new-flag: #{variables.$twyford-secondary-emerald};

  // headline weights
  --h1-weight: #{variables.$font-weight-light};
  --h2-weight: #{variables.$font-weight-light};
  --h3-weight: #{variables.$font-weight-light};

  // h4-6 are bold by default

  // headline transform
  --hd-transform: #{variables.$text-transform-cap};
}

// Global typo
// -----------------------------
body {
  @include mixins.hyphens();
  background: variables.$body-background;
  color: variables.$gb_black;
  font-family: variables.$body-font-family;
  font-size: variables.$gb_fontSizeBase;
  line-height: variables.$gb_lineHeightBase;
  -webkit-overflow-scrolling: touch;
}

// Helper classes
// -----------------------------
.bold,
b {
  font-weight: variables.$font-weight-bold;
}

.has {
  &--background-spacing {
    padding: 3.4375rem 0 2.8125rem;

    > .grid-container {
      > h1,
      > .h1,
      > h2,
      > .h2,
      > h3,
      > .h3,
      > h4,
      > .h4,
      > h5,
      > .h5,
      > h6,
      > .h6 {
        margin-top: 0;
      }
    }
  }

  &--background {
    &--grey-050 {
      background-color: variables.$gb_grey_050;
    }
  }
}

// Global Background Styles
// -----------------------------
.bg-white-teaser {
  .c-tiles__wrapper {
    background-color: variables.$gb_grey_050;
  }
}

.bg-grey-teaser {
  background-color: variables.$gb_grey_055;
}

.bg--sunlight-dark,
.bg-sunlight-dark-teaser {
  background-color: variables.$gb_yellow;
}

.bg--sky-dark,
.bg-sky-dark-teaser {
  background-color: variables.$gb_dark-grey-blue;

  .title--block {
    color: variables.$gb_white;
  }
}

.bg--ocean-dark,
.bg-ocean-dark-teaser {
  background-color: variables.$gb_turquoise;

  .title--block {
    color: variables.$gb_white;
  }
}

.bg--forest-dark,
.bg-forest-dark-teaser {
  background-color: variables.$gb_dark_forest;

  .title--block {
    color: variables.$gb_white;
  }
}

.bg--wood-dark,
.bg-wood-dark-teaser {
  background-color: variables.$gb_brown;

  .title--block {
    color: variables.$gb_white;
  }
}

.bg--light-grey,
.bg-light-grey-teaser {
  background-color: variables.$gb_grey_060;
}

.bg--medium-grey,
.bg-medium-grey-teaser {
  background-color: variables.$gb_grey_100;
}

.bg--rock-dark,
.bg-rock-dark-teaser {
  background-color: variables.$gb_grey_750;

  .title--block {
    color: variables.$gb_white;
  }
}

.bg--blue-grey,
.bg-blue-grey-teaser {
  background-color: variables.$gb_dark-grey-blue;

  h2 {
    color: variables.$gb_white;
  }

  .title--block {
    color: variables.$gb_white;
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    &::after {
      color: variables.$gb_grey_100;
    }

    &:hover::after {
      color: variables.$gb_white;
    }
  }
}

.display-block {
  display: block !important;
}

.mi-player {
  ~ .mi-player {
    display: none;
  }
}

@media print {
  footer,
  #page-header {
    display: none;
  }

  main {
    padding-top: 0 !important;
  }

  button.plain {
    display: none !important;
  }

  iframe.start_chat_button {
    display: none;
  }

  #Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window {
    display: none;
  }
}

.grid-container {
  padding-left: 1rem;
  padding-right: 1rem;

  @include mixins.screen-sm {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.gtmLcmOuter {
  bottom: 1.25rem !important;
  right: 1.25rem !important;
}

.gdds-dialogue {
  &#{&}#{&} {
    z-index: 99;
  }
}

.gigya-screen.portrait {
  @include mixins.below-screen-sm {
    width: 100% !important;
  }
}
