@use '../base/mixins';
@use '../base/variables';

.c-text-image {
  border-bottom: variables.$border-black;
  margin-bottom: variables.$section-margin;
  padding-bottom: variables.$section-padding;

  .c-text-image__teaser,
  .c-text-image__text {
    @include mixins.bullet-points-spacing();
  }

  &--disable-border {
    border: none;
    padding-bottom: 0;
  }

  &__image {
    margin-top: 0.4375rem;
  }

  &--text-right,
  &--text-right-small,
  &--text-left,
  &--text-left-small {
    .single-image-wrapper {
      margin-bottom: 0.625rem;

      @include mixins.breakpoint(large) {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__text {
    ul:first-of-type {
      @include mixins.breakpoint(large) {
        margin-top: 0;
      }
    }

    & > :last-child {
      > :last-child {
        margin-bottom: 0;
      }
    }

    p {
      max-width: 100%;

      @include mixins.breakpoint(large) {
        margin-top: 0;
      }

      a::before {
        padding-right: 5px;
      }
    }

    .table-outer-wrapper {
      width: auto;
      height: auto;
      overflow: auto;
    }

    b {
      font-weight: variables.$font-weight-bold;
    }

    a {
      padding: 0;

      &::before {
        position: relative;
        top: 0;
      }
    }

    .button {
      margin-top: 3.125rem;

      &--white {
        color: variables.$gb_white;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--text-right {
    margin-top: 1.875rem;

    @include mixins.breakpoint(medium) {
      display: flex;
      margin-top: 0;
    }

    .c-text-image__image {
      order: 1;
      flex-basis: 50%;
    }

    .c-text-image__text {
      order: 2;
      flex-basis: 50%;

      @include mixins.breakpoint(medium) {
        padding-left: 4.375rem;
      }

      p {
        @include mixins.breakpoint(large) {
          margin-top: 0;
        }
      }
    }
  }

  &--text-right-small {
    @include mixins.breakpoint(medium) {
      display: flex;
    }

    .c-text-image__image {
      order: 1;
      flex-basis: 33%;
    }

    .c-text-image__text {
      order: 2;
      flex-basis: 66%;

      @include mixins.breakpoint(medium) {
        padding-left: 4.375rem;
      }
    }
  }

  &--text-left {
    @include mixins.breakpoint(medium) {
      display: flex;
    }

    .c-text-image__image {
      order: 2;
      flex-basis: 50%;
    }

    .c-text-image__text {
      order: 1;
      flex-basis: 50%;

      @include mixins.breakpoint(medium) {
        padding-right: 4.375rem;
      }
    }
  }

  &--text-left-small {
    @include mixins.breakpoint(medium) {
      display: flex;
    }

    .c-text-image__image {
      order: 2;
      flex-basis: 33%;
    }

    .c-text-image__text {
      order: 1;
      flex-basis: 66%;

      @include mixins.breakpoint(medium) {
        padding-right: 4.375rem;
      }
    }
  }

  &--text-full {
    @include mixins.breakpoint(large) {
      flex-direction: column;
    }

    .c-text-image__text {
      order: 2;

      .image--aspect-ratio:last-of-type,
      ol:last-of-type {
        margin-bottom: 0;
      }
    }

    .c-text-image__image {
      order: 1;
      margin-bottom: 1.875rem;

      @include mixins.breakpoint(large) {
        display: flex;
        flex-direction: row;
      }

      .single-image-wrapper {
        width: 100%;
        margin: 0 0 1.875rem 0;

        &:last-of-type {
          padding-bottom: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        @include mixins.breakpoint(large) {
          margin: 0 1.875rem 0 0;
        }
      }
    }
  }

  &--teaser-column {
    @include mixins.breakpoint(large) {
      display: flex;
      flex-basis: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }

    h3 {
      margin-bottom: 1.25rem;
    }

    .c-text-image {
      &__image {
        order: 1;

        @include mixins.breakpoint(large) {
          flex-basis: 70%;
          padding-right: 7.3125rem;
        }

        .c-text-image__image {
          padding-bottom: 2.5em;
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }

      &__text {
        order: 3;

        span {
          font-weight: bold;
          display: block;
        }
      }

      &__teaser {
        padding-top: 1.875rem;
        order: 2;

        @include mixins.breakpoint(large) {
          padding: 0;
          flex-basis: 30%;
        }
      }
    }
  }
}
