@keyframes movedown {
  0% {
    transform: translate(0, -10px);
  }
  100% {
    transform: translate(0, 25px);
  }
}

@keyframes moveup {
  0% {
    transform: translate(0, 25px);
  }
  100% {
    transform: translate(0, -10px);
  }
}

@keyframes transalteIcon {
  20% {
    transform: rotate(0deg);
    width: 1.3px;
  }
  40% {
    transform: rotate(25deg);
    width: 1.2px;
  }
  60% {
    transform: rotate(45deg);
    width: 1px;
  }
  100% {
    transform: rotate(45deg);
    width: 1px;
    bottom: 1px;
  }
}

@keyframes translateBefore {
  20% {
    width: 1px;
  }
  40% {
    width: 1.1px;
  }
  60% {
    width: 1.2px;
  }
  100% {
    width: 1.2px;
  }
}

@keyframes translateAfter {
  20% {
    width: 1.5px;
    height: 6px;
  }
  40% {
    width: 1px;
    height: 3px;
  }
  60% {
    height: 0;
    width: 0;
  }
  100% {
    height: 0;
    width: 0;
  }
}
