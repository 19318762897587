@use '../base/functions';
@use '../base/mixins';
@use '../base/variables';

.c-accordion {
  display: block;
  max-width: variables.$grid-container;
  background: variables.$body-background;
  padding-bottom: variables.$section-padding;

  *:last-child .c-accordion__item,
  > .c-accordion__item:last-child {
    border-bottom: 1px solid variables.$gb_grey_400;
  }

  .grid-container:last-of-type {
    .c-text-image {
      margin-bottom: variables.$section-margin;
    }

    .c-image-gallery {
      border: none;
      padding-bottom: 0;
    }
  }

  h2 + & {
    padding-top: 0;
  }

  &__item {
    border-top: 1px solid variables.$gb_grey_400;

    h3,
    h4:not(.h3) {
      font-weight: variables.$font-weight-bold;

      .icon {
        padding-left: 10px;
      }
    }
  }

  &__title {
    color: variables.$gb_black;
    padding: 1.1875rem 0;

    &[hidden] {
      color: var(--gb-brand-primary-dark);
      margin-bottom: 0;

      h3,
      h4 {
        font-weight: normal;
      }

      button::before {
        color: var(--gb-brand-primary-dark);
        font-size: 1.25rem;
      }
    }

    h3 {
      margin: 0;
    }

    @include mixins.breakpoint(medium) {
      padding: 1.5rem 0;
    }

    @include mixins.breakpoint(large) {
      margin-bottom: 10px;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    button {
      padding-right: 0.625rem;
      text-align: right;

      &:focus {
        outline: none;
      }
    }

    button::before {
      @include mixins.web20-icon('close');
      font-size: 0.9375rem;
    }

    h3:not(.h6),
    h4 {
      @include mixins.breakpoint(medium) {
        font-size: 1.125rem;
        letter-spacing: functions.calc-letter-spacing(20);
        line-height: 1.375rem;
      }
    }

    &[aria-expanded='false'],
    &--hidden {
      color: var(--gb-brand-primary-light);
      margin-bottom: 0;

      h3:not(.h6),
      h4 {
        font-weight: normal;
      }

      button::before {
        @include mixins.web20-icon('plus');
        color: var(--gb-brand-primary-light);
        font-size: 1.25rem;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-rows: 1fr;
    margin-bottom: 1.875rem;

    & > .c-accordion__content {
      overflow: hidden;

      ul,
      ol {
        margin-top: 0;
      }

      @include mixins.bullet-points-spacing();
    }

    &[aria-hidden='true'],
    &[hidden] {
      display: grid;
      grid-template-rows: 0fr;
      margin-bottom: 0;
    }

    section,
    .grid-container:not(.c-video-content__video-title) {
      padding: 0;
    }

    a.gdds-element {
      &:before {
        content: none;
      }
    }
  }

  .c-text-image {
    border: 0;
    padding-bottom: 0;
  }

  .c-tiles {
    border: 0;
    padding: 0;
  }

  .link-list {
    list-style: disc;
  }
}

.c-accordion--faq {
  > div {
    > div:first-child {
      .c-accordion__item {
        border-top-width: 1px;
      }
    }
  }

  .c-accordion__item {
    border-top-width: 0;
  }
}
