@use '../base/functions';
@use '../base/mixins';
@use '../base/variables';
@use 'main-mobile-navigation';

@use 'sass:math';

.c-search {
  .c-search-bar {
    box-shadow:
      variables.$box-shadow-main-nav-top,
      inset variables.$box-shadow-main-nav-top;

    &-form {
      position: absolute;
    }
  }

  &__close {
    @include mixins.breakpoint(large) {
      position: absolute;
      top: 1.875rem;
      right: math.div(main-mobile-navigation.$menu-mobile-grid-gutter-large, 2);

      i::before {
        color: var(--gb-brand-primary);
      }
    }
  }
}

.c-search-bar {
  $element: &;

  &-form {
    border: 0;
    left: 0;
    margin: 0;
    padding-bottom: 0;
    position: relative;
    right: 0;
    top: 100%;

    @include mixins.screen-md {
      border-top: 1px solid variables.$gb_grey_150;
    }

    &.c-search-bar-form--white-bg {
      opacity: 1;
      background-color: variables.$gb_white;
      height: 0px;
      transition: height variables.$transition-fast variables.$transition-timing-ease-out;
      overflow: hidden;

      .nord & {
        top: 3.6875rem;

        @include mixins.screen-md {
          top: 100%;
        }
      }

      input[type='text'] {
        opacity: 0;
        transition: opacity variables.$transition-fast variables.$transition-timing-ease-out 0.4s;
      }
      .c-search-bar__btn {
        opacity: 0;
        transition: opacity variables.$transition-fast variables.$transition-timing-ease-out 0.4s;
      }

      &-active {
        opacity: 1;
        height: 100vh;
        z-index: 50;

        input[type='text'] {
          opacity: 1;
        }

        .c-search-bar__btn {
          opacity: 1;
        }

        .c-search-bar__line--animation {
          &::before {
            right: 0 !important;
            left: 0 !important;
          }
        }
      }

      .c-suggestion-flyout {
        position: relative;
        box-shadow: none;
        top: 5.7rem;
        border-top: none;

        @include mixins.breakpoint(medium) {
          top: 10.4rem;
        }

        & > div [role='option'] {
          padding-left: 0;

          &:hover {
            color: var(--gb-brand-primary);
          }
        }

        & [data-active='true'] {
          background-color: transparent;
        }
      }

      #{$element} {
        box-shadow: none;
        padding-top: 0;
        height: 0;

        & > div {
          position: relative;
          justify-content: center;
        }

        &--active {
          &#{$element}__line:before {
            background-color: var(--gb-brand-primary);
          }

          #{$element}__input,
          #{$element}__btn i::before {
            color: var(--gb-brand-primary);
          }
        }

        &__line {
          transform: translateZ(0);
          padding-top: 4.6875rem;
          z-index: 50;
          border-bottom: none;

          &:before {
            content: '';
            position: absolute;
            background-color: variables.$gb_grey_600;
            z-index: -1;
            left: 50%;
            right: 50%;
            bottom: 0;
            height: 1px;
            transition:
              left 0.3s ease-out 0.1s,
              right 0.3s ease-out 0.1s;
          }

          @include mixins.breakpoint(medium) {
            padding-top: 9.375rem;
          }
        }

        &__input,
        &__input::placeholder,
        i::before {
          color: variables.$gb_grey_600;
        }
      }
    }

    &--visible {
      height: 100vh;
      transition: height 0.3s ease-in-out;
      visibility: visible;
    }

    &--grey-bg {
      background-color: variables.$gb_grey_060;
      margin-bottom: 5rem;
    }
  }
}

// Suggestion Flyout
// -----------------------------
.c-suggestion-flyout {
  background-color: variables.$gb_white;
  border-top: 1px solid variables.$gb_grey_150;
  box-shadow: 0 10px 1.5625rem variables.$gb_grey_150;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  top: 2.6875rem;
  transform: translate(0, 1.875rem);
  width: 100%;
  z-index: 21;
  @include mixins.breakpoint(medium) {
    top: 4.5rem;
  }

  > div {
    cursor: pointer;
    line-height: 2;
    justify-content: center;
    display: flex;

    & em {
      font-family: variables.$body-font-family;
      font-style: normal;
      font-weight: variables.$font-weight-bold;
    }

    & [role='option'] {
      padding-left: 1.5625rem;
      padding-right: 1.5625rem;
    }

    & [data-active='true'] {
      background-color: variables.$gb_grey_060;
    }
  }
}

.c-search-bar-placeholder {
  height: 7.5rem;
}

.c-search-bar-form .c-search-bar,
.c-search-bar {
  background-color: variables.$gb_white;
  padding-top: 2.1875rem;
  z-index: variables.$z-search-bar;

  @include mixins.breakpoint(medium) {
    height: variables.$search-bar-height;
    padding-top: functions.calc-center(160, 45);
  }

  &--homepage {
    background-color: variables.$gb_grey_060;
    height: auto;
    padding: 2.8125rem 0 1.5625rem;
  }

  &__input {
    background: transparent;
    border-style: none;
    color: var(--gb-brand-primary);
    font-size: 1.25rem;
    height: 2.8125rem;
    opacity: 1;
    padding: 0 0 10px 0.8125rem;

    &:hover {
      box-shadow: none;
      outline: 0;
    }

    &::placeholder {
      color: var(--gb-brand-primary);
      opacity: 1;
      position: relative;
    }

    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }

  &__btn-container {
    text-align: right;
  }

  &__btn {
    font-size: 1.25rem;
    padding: 5px 0.8125rem 0 0;

    &:focus {
      outline: 0;
    }
  }

  &__line {
    display: flex;
    position: relative;
    background: transparent;
    border-bottom: 1px solid variables.$gb_grey_150;
    padding: 0;
    max-width: 75rem;
  }
}

#page-header {
  .c-search {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button i::before {
      font-size: functions.calc-pixel(20);
    }

    .c-search-bar-form {
      &__line {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  .c-search-bar {
    input[type='text'] {
      border: 0;
      box-shadow: none;
      padding-left: 0;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }
}
