@use "../base/variables";

.c-tpp-area {
  min-height: 50px;

  // translation error showing red for preview
  &__translation-error {
    color: variables.$gb_brand-utility-negative !important;
  }

  &__placeholder {
    padding: variables.$section-padding 0;
    border: 3px dashed variables.$gb_grey_400;
    text-align: center;
    font-size: 1.25rem;
    color: variables.$gb_grey_400;

    em {
      margin-left: 0.9375rem;
    }

    &[data-edit-area="landingpage_content"] {
      margin-top: 6rem;
    }
  }

  &__bgcrop {
    position: absolute;
    top: 1.5625rem;
  }
}

.tpp-buttons-container {
  ul {
    margin: 0;

    li {
      position: relative;
      padding-left: 20px;

      &::before {
        position: absolute;
        left: 0;
      }
    }
  }
}

.tpp-icon-up::after {
  width: 100%;
  background-color: #46446b !important;
  background-image: url('/images/technical/content-creator/move_up.png') !important;
  background-position: center center;
  background-repeat: no-repeat;
}

.tpp-icon-down::after {
  width: 100%;
  background-color: #46446b !important;
  background-image: url('/images/technical/content-creator/move_down.png') !important;
  background-position: center center;
  background-repeat: no-repeat;
}

.tpp-icon-link::after {
  width: 100%;
  background-color: #46446b !important;
  background-image: url('/images/technical/content-creator/icon-link.svg') !important;
  background-position: center center;
  background-repeat: no-repeat;
}

section.grid-container > .c-tpp-area {
  padding-top: 25px;
  margin-top: -25px;
}
