@use 'variables';

:root {
  --gb-brand-primary: #{variables.$platform-primary};
  --gb-brand-primary-light: #{variables.$platform-primary-light};
  --gb-brand-primary-dark: #{variables.$platform-primary-dark};
  --product-tiles-new-flag: #{variables.$platform-primary-light};
  --uc-heading-title-font-weight: 400;

  .nord {
    --gb-brand-primary: #{variables.$nord-primary};
    --gb-brand-primary-light: #{variables.$nord-primary-light};
    --gb-brand-primary-dark: #{variables.$nord-primary-dark};
    --product-tiles-new-flag: #{variables.$nord-secondary-emerald};
  }

  .kolo {
    --gb-brand-primary: #{variables.$kolo-primary};
    --gb-brand-primary-light: #{variables.$kolo-primary-light};
    --gb-brand-primary-dark: #{variables.$kolo-primary-dark};
    --product-tiles-new-flag: #{variables.$kolo-secondary-emerald};
    --uc-heading-title-font-weight: 700;
  }

  .twyford {
    --gb-brand-primary: #{variables.$twyford-primary};
    --gb-brand-primary-light: #{variables.$twyford-primary-light};
    --gb-brand-primary-dark: #{variables.$twyford-primary-dark};
    --product-tiles-new-flag: #{variables.$twyford-secondary-emerald};
    --uc-heading-title-font-weight: 700;
  }
}
