@use '../base/variables';
@use '../base/mixins';

$download-center-indicator: #9bc444;

.dc-tiles-area {
  &__wrapper {
    justify-content: space-around;
    margin-bottom: 7.5rem;
    margin-top: 1.875rem;

    button {
      width: 100%;

      @include mixins.breakpoint(medium) {
        min-width: calc(33.33333% - 1.875rem);
      }

      i::before {
        font-size: 1.375rem;
      }
    }
  }
}

.dc-tiles {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1.875rem;

  &__overlay {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5625rem;

    @include mixins.breakpoint(xlarge) {
      background: var(--gb-brand-primary-light);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      color: variables.$gb_white;
      text-transform: uppercase;
      text-align: center;
      font-size: 0.625rem;
      padding: 1.875rem 3.125rem;
      opacity: 0;
      margin-top: 0;
    }

    &__interaction {
      color: var(--gb-brand-primary) !important;
      font-size: 1rem;
      text-align: left;
      margin-right: 1.25rem;
      margin-bottom: 0.625rem;

      &:hover {
        text-decoration: none;
      }

      i {
        margin-right: 5px;
        font-size: 1.125rem;

        @include mixins.breakpoint(xlarge) {
          font-size: 1.875rem;
        }

        &::before {
          color: var(--gb-brand-primary);

          @include mixins.breakpoint(xlarge) {
            color: variables.$gb_white;
          }
        }
      }

      @include mixins.breakpoint(xlarge) {
        color: variables.$gb_white !important;
        flex-basis: 0 0 auto;
        font-size: 0.625rem;
        height: 50%;
        line-height: 1;
        margin-right: 0;
        padding: 0;
        padding-bottom: 1.5625rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 50%;

        &:hover i::before {
          background: variables.$gb_white;
          color: var(--gb-brand-primary-light);
        }
      }

      &::before {
        content: none;
      }
    }

    i {
      @include mixins.breakpoint(xlarge) {
        font-size: 1.875rem;
        display: block;
        margin-bottom: 0.625rem;
        cursor: pointer;

        &::before {
          border: 1px solid variables.$gb_white;
          border-radius: 1.75rem;
          padding: 5px;
          color: variables.$gb_white;
        }
      }
    }
  }

  &:hover .dc-tiles__overlay {
    opacity: 1;
  }

  &__border {
    border: 2px solid variables.$gb_grey_055;
    padding: 1.25rem;
    position: relative;
  }

  p {
    margin: 0;
  }

  &__badges {
    display: flex;
    position: absolute;
    top: -0.9375rem;
    right: 0.9375rem;
    z-index: 4;

    i {
      background: $download-center-indicator;
      width: 1.875rem;
      height: 1.875rem;
      margin-right: 0.625rem;
      border-radius: 50%;

      &::before {
        display: block;
        font-size: 1.3125rem;
        line-height: 1.4;
        text-indent: 0;
        text-align: center;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: row;
    margin-bottom: 3.125rem;
    min-height: 5.875rem;

    img {
      flex-shrink: 0;
      width: 4.25rem;
      height: 5.875rem;
      margin-right: 1.25rem;
      object-fit: cover;
    }
  }

  &__info {
    color: variables.$gb_grey_400;
    display: flex;
    flex-direction: row;
    align-items: baseline;

    @include mixins.breakpoint(large) {
      color: variables.$gb_black;
    }

    i {
      font-size: 1.25rem;
      padding-right: 5px;

      &::before {
        color: variables.$gb_grey_400;

        @include mixins.breakpoint(large) {
          color: var(--gb-brand-primary-light);
        }
      }
    }

    span {
      padding-left: 1.25rem;
    }
  }
}
