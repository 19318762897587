@use "../base/mixins";
@use "../base/variables";

.c-tooltip {
  &__content {
    background: variables.$gb_grey_800;
    max-width: 18.75rem;
    padding: 0.625rem;
    visibility: hidden;
    position: absolute;
    z-index: variables.$z-tooltip;
    font-size: 0.75rem;
    color: variables.$gb_white;
    line-height: 1.4;
    animation: tooltip-fade-in variables.$transition-very-fast variables.$transition-timing-ease-in-out;

    &--dismissable {
      padding-right: 1.5625rem;
    }
  }

  &__content--plain {
    background: variables.$gb_white;
    max-width: 18.75rem;
    padding: 0.625rem;
    visibility: hidden;
    position: absolute;
    z-index: variables.$z-tooltip;
    font-size: 0.75rem;
    line-height: 1.4;
    white-space: nowrap;
    width: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    animation: tooltip-fade-in variables.$transition-very-fast variables.$transition-timing-ease-in-out;

    a::before {
      content: none;
    }

    i {
      margin-right: 8px;
    }
  }

  &__content--white {
    background: variables.$gb_white;
    padding: 2.125rem 1.5rem 0.25rem 1.5rem;
    visibility: hidden;
    position: absolute;
    z-index: variables.$z-tooltip;
    font-size: 0.75rem;
    line-height: 1.4;
    white-space: nowrap;
    height: auto;
    width: auto;
    min-width: 16rem;
    margin-left: 1.875rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    animation: tooltip-fade-in variables.$transition-very-fast variables.$transition-timing-ease-in-out;

    @include mixins.screen-sm {
      margin-left: 4.875rem;
    }

    @include mixins.screen-md {
      margin-left: 4.5rem;
    }

    @include mixins.screen-lg {
      margin-left: 6.5rem;
    }

    a::before {
      content: none;
    }

    i {
      margin-right: 0.5rem;
    }

    .tooltip-text {
      font-size: 0.875rem;

      ul {
        margin-top: 0.875rem;
        padding-left: 0;
        li {
          list-style-type: none;

          &::before {
            @include mixins.web20-icon('check');
            color: #008819;
            margin-right: 0.75rem;
          }
        }
      }
    }
  }

  &__close {
    width: 1.5625rem;
    height: 1.5625rem;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.75rem;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__arrow {
    display: block;
    content: '';
    position: absolute;
    bottom: -0.625rem;
    transform: translateX(-50%);
    border-top: 0.625rem solid variables.$gb_grey_800;
    border-right: 0.625rem solid transparent;
    border-left: 0.625rem solid transparent;

    &--plain {
      display: none;
    }

    &--white {
      display: block;
      content: '';
      position: absolute;
      top: -10px;
      transform: inherit;
      border-bottom: 0.625rem solid variables.$gb_white;
      border-right: 0.625rem solid transparent;
      border-left: 0.625rem solid transparent;
      margin-left: -1.563rem;

      @include mixins.screen-sm {
        transform: inherit;
        margin-left: -4.563rem;
      }

      @include mixins.screen-md {
        margin-left: -4.375rem;
      }

      @include mixins.screen-lg {
        margin-left: -6rem;
      }
    }
  }
}

@keyframes tooltip-fade-in {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
