@use '../base/mixins';
@use '../base/variables';

.c-text-video {
  $element: &;
  margin-bottom: variables.$section-margin;
  padding-bottom: variables.$section-padding;
  border-bottom: variables.$border-black;

  .c-text-video__text {
    @include mixins.bullet-points-spacing();
  }

  &__videocookie {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.8);
    color: variables.$gb_white;

    span {
      margin-top: 1.25rem;
    }
  }

  &--disable-border {
    border: none;
    padding-bottom: 0;
  }

  &__wrapper {
    display: flex;
    @include mixins.flex-flow();
  }

  &__wrapper #{$element}__text {
    flex-basis: 100%;
    margin-top: 2.25rem;

    @include mixins.breakpoint(medium) {
      max-width: 50%; // IE11 bugfix (otherwise padding breaks layout)
      flex-basis: 50%;
      margin-top: 0;

      .c-video-center__channel & {
        max-width: 33.3333%; // IE11 bugfix (otherwise padding breaks layout)
        flex-basis: 33.3333%;
        padding-left: 3.75rem;
      }
    }
  }

  &__text {
    .separate-title {
      margin-bottom: 1.25rem;
    }

    *:last-child {
      margin-bottom: 0;
    }

    .table-outer-wrapper {
      width: auto;
      height: auto;
      overflow: auto;
    }
  }

  &__wrapper--left {
    @include mixins.breakpoint(medium) {
      #{$element}__video {
        padding-right: 1.875rem;
        order: 1;
      }

      #{$element}__text {
        padding-left: 4.375rem;
        order: 2;
      }
    }
  }

  &__wrapper--right {
    @include mixins.breakpoint(medium) {
      #{$element}__video {
        padding-left: 1.875rem;
        order: 2;
      }

      #{$element}__text {
        padding-right: 4.375rem;
        order: 1;
      }
    }
  }

  &__wrapper #{$element}__video {
    flex-basis: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
    height: 100%;

    @include mixins.breakpoint(medium) {
      max-width: 50%; // IE11 bugfix (otherwise padding breaks layout)
      flex-basis: 50%;
      margin-top: 0.375rem;

      .c-video-center__channel & {
        max-width: 66.6666%; // IE11 bugfix (otherwise padding breaks layout)
        flex-basis: 66.6666%;
        padding-right: 0.9375rem;
      }
    }
  }

  &__video-module {
    margin: 0;
    position: relative;
    background: center no-repeat;
    background-size: cover;

    .mi-player-generator,
    .mi-big-play,
    .big-play-button {
      display: none !important;
    }

    a {
      margin-left: 0;

      &::before {
        display: none;
      }
    }

    .mi-error {
      position: absolute;
      padding-top: 50%;
    }
  }

  .mi-dropdown-list li {
    padding: 0;

    a {
      padding: 0.5rem 1.875rem 0.5rem 1.25rem;
      text-decoration: none;
    }
  }

  .mi-controls.controls {
    width: 100% !important;
  }
}
