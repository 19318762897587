.loIQBs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
@media (min-width: 48rem) {
  .loIQBs {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
}
@media (min-width: 0) {
  .loIQBs {
    -webkit-flex-direction: inherit;
    -ms-flex-direction: inherit;
    flex-direction: inherit;
  }
}
.iBjvEf {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  box-sizing: border-box;
}
@media (min-width: 48rem) {
  .iBjvEf {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}
@media (min-width: 64rem) {
  .iBjvEf {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}
.kjROlt {
  position: relative;
  max-width: 90rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (min-width: 48rem) {
  .kjROlt {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
.iVufAh {
  font-family: 'AktivGrotesk', helvetica, arial, sans-serif;
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 2.25rem;
  text-transform: uppercase;
}
@media (min-width: 48rem) {
  .iVufAh {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
}
@media (min-width: 64rem) {
  .iVufAh {
    font-size: 2.625rem;
    line-height: 3.25rem;
  }
}
@media (min-width: 80rem) {
  .iVufAh {
    font-size: 2.875rem;
    line-height: 3.75rem;
  }
}
.jHRhUG {
  font-family: 'AktivGrotesk', helvetica, arial, sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.25rem;
  text-transform: inherit;
}
@media (min-width: 48rem) {
  .jHRhUG {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
}
@media (min-width: 64rem) {
  .jHRhUG {
    font-size: 2.625rem;
    line-height: 3.25rem;
  }
}
@media (min-width: 80rem) {
  .jHRhUG {
    font-size: 2.875rem;
    line-height: 3.75rem;
  }
}
.eQJbxf {
  font-family: 'AktivGrotesk', helvetica, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: inherit;
}
.farbKz {
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'AktivGrotesk', helvetica, arial, sans-serif;
  font-weight: 500;
  background-color: transparent;
  color: #000000;
  border: 1px solid #000000;
  box-sizing: border-box;
  white-space: nowrap;
  min-width: 6.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  height: 2.5rem;
  padding: 0.5625rem 1.5rem;
}
.farbKz span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
.farbKz svg {
  width: 1rem;
  fill: #000000;
  height: 2.5rem;
  min-width: 2.5rem;
}
@media (hover: hover) {
  .farbKz:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}
.farbKz:focus {
  outline: none;
}
.farbKz::-moz-focus-inner {
  border: 0;
}
.farbKz:active {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  background-color: #3d3d3d;
  border: 1px solid rgba(0, 0, 0, 0);
}
.farbKz:active svg {
  fill: #ffffff;
}
.farbKz:disabled {
  cursor: default;
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.26);
}
.farbKz:disabled svg {
  fill: rgba(0, 0, 0, 0.26);
}
@media (min-width: 20rem) {
  .farbKz {
    min-width: 6.25rem;
  }
  .farbKz span {
    height: 2.5rem;
    min-width: 2.5rem;
  }
}
@media (min-width: 48rem) {
  .farbKz {
    min-width: 6.25rem;
    padding: 0.3125rem 1.5rem;
  }
  .farbKz span {
    height: undefinedrem;
    min-width: undefinedrem;
  }
}
@media (min-width: 64rem) {
  .farbKz {
    min-width: 6.25rem;
    padding: 0.3125rem 1.5rem;
  }
  .farbKz span {
    height: undefinedrem;
    min-width: undefinedrem;
  }
}
@media (min-width: 80rem) {
  .farbKz {
    min-width: 6.25rem;
  }
  .farbKz span {
    height: undefinedrem;
    width: undefinedrem;
  }
}
@media (hover: none) and (pointer: coarse) and (min-width: 0) and (max-width: 48rem) {
  .farbKz {
    height: 2.75rem;
    min-width: 6.25rem;
  }
  .farbKz span {
    height: 2.75rem;
    min-width: 2.75rem;
  }
}
.cuWIgZ {
  box-sizing: border-box;
  border: none;
  margin-block-start: 0;
  margin-block-end: 0;
  width: 100%;
  height: 1px;
  border-top: 1px solid;
  border-color: rgba(0, 0, 0, 0.16);
}
.cTvjmP {
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: none;
}
.cTvjmP::before {
  content: '';
  display: block;
  padding-top: 100%;
}
.cTvjmP > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

.jtiRfN {
  color: #999999;
  display: block;
  font-size: 0.75rem;
  line-height: 1;
  margin-left: 0.5rem;
  word-break: normal;
  -webkit-align-self: baseline;
  -ms-flex-item-align: baseline;
  align-self: baseline;
}
.itRsiY {
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.625rem 0;
  border: 1px solid transparent;
  outline: none;
  outline-offset: 0;
}
.enfdLN {
  width: 9.125rem;
  height: 1.25rem;
}
.bmrzLJ {
  height: 6.25rem;
  background-color: #ffffff;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.2);
  position: relative;
}

.flxWwF {
  min-height: 16.25rem;
}
.cphEXD {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  padding: 4rem 0;
}
@media (min-width: 48rem) {
  .cphEXD {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 4rem 0 2rem;
  }
}
@media (min-width: 64rem) {
  .cphEXD {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.bKChHi {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (min-width: 48rem) {
  .bKChHi {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    min-height: 3.75rem;
  }
}
@media (min-width: 48rem) {
  .bKChHi:first-child {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.bKChHi:not(:first-child) {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  margin-top: 3rem;
}
@media (min-width: 48rem) {
  .bKChHi:not(:first-child) {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 1rem;
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    min-height: 2.5rem;
  }
  .bKChHi:not(:first-child) ul {
    margin-left: 0;
  }
}
@media (min-width: 64rem) {
  .bKChHi:not(:first-child) {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
.iclRGb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 1.25rem;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5c5c5c;
  margin-top: 0.5rem;
}
.iclRGb.iclRGb.iclRGb {
  font-size: 0.875rem;
  font-weight: 400;
}
@media (min-width: 48rem) {
  .iclRGb {
    height: auto;
    margin-right: 0;
    margin-top: 0;
  }
}
.eWTGZe {
  display: grid;
  margin: 1rem 0;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(288px, auto);
  grid-gap: 2rem;
}
@media (min-width: 48rem) {
  .eWTGZe {
    grid-template-columns: 1fr 6fr 1fr;
    grid-auto-rows: minmax(350px, auto);
    grid-gap: 0rem;
    margin: 1.5rem 0;
  }
}
@media (min-width: 64rem) {
  .eWTGZe {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(500px, auto);
    grid-gap: 1.5rem;
  }
}
@media (min-width: 80rem) {
  .eWTGZe {
    width: 100%;
    grid-template-columns: 1fr 5fr 5fr 1fr;
    grid-gap: 0rem;
  }
}
.gyWQfZ {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column: 1;
  grid-row: 2;
}
@media (min-width: 48rem) {
  .gyWQfZ {
    grid-column: 2;
    grid-row: 2;
  }
}
@media (min-width: 64rem) {
  .gyWQfZ {
    grid-column: 1;
    grid-row: 1;
  }
}
@media (min-width: 80rem) {
  .gyWQfZ {
    grid-column: 2;
    grid-row: 1;
  }
}
.fnnyPD {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
}
.fnnyPD h6 {
  margin: 0 0 0.5rem 0;
  font-weight: bold;
}
.fnnyPD p {
  margin: 0;
}
@media (min-width: 64rem) {
  .ebuPNI {
    margin-top: 3.75rem;
  }
}
.ebuPNI h1 {
  margin: 0;
}
.ebuPNI span {
  display: block;
}
.fhglIl {
  width: 16rem;
  margin: 2rem 0 1rem 0;
}
@media (min-width: 48rem) {
  .fhglIl {
    margin: 2rem 0 1.5rem 0;
  }
}
.brPxdw {
  grid-column: 1;
  grid-row: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 48rem) {
  .brPxdw {
    grid-column: 2;
    grid-row: 1;
  }
}
@media (min-width: 64rem) {
  .brPxdw {
    grid-column: 2;
    grid-row: 1;
  }
}
@media (min-width: 80rem) {
  .brPxdw {
    grid-column: 3;
    grid-row: 1;
  }
}
.brPxdw.brPxdw > div {
  min-height: 288px;
}
@media (min-width: 48rem) {
  .brPxdw.brPxdw > div {
    max-height: 350px;
    max-width: 350px;
  }
}
@media (min-width: 64rem) {
  .brPxdw.brPxdw > div {
    max-height: 500px;
    max-width: 500px;
    margin-left: 0;
  }
}
.fgGVqr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.cls-2,
.cls-5 {
  stroke: #3d3d3d;
}
.cls-16,
.cls-2,
.cls-5 {
  stroke-miterlimit: 10;
  stroke-width: 4px;
}
.cls-4 {
  fill: #f1f1f1;
}
.cls-16,
.cls-5 {
  fill: none;
}
.cls-6 {
  fill: #3d3d3d;
}
.cls-10 {
  fill: #e6e6e6;
}
.cls-11 {
  fill: #b4cdf0;
}
.cls-16 {
  stroke: #999;
}
