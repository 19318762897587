@use '../base/functions';
@use '../base/mixins';
@use '../base/variables';

.c-all-collection-filter {
  margin-bottom: 2.5rem;

  @include mixins.breakpoint(small down) {
    margin-bottom: 0;
  }

  .filter-group {
    margin-bottom: 1.875rem;

    @include mixins.breakpoint(small down) {
      margin-bottom: 1rem;

      form {
        padding: 0;
        margin: 0;
        border: 0;

        .form-item {
          margin-bottom: 0;

          select {
            background-color: variables.$gb_grey_060;
          }
        }
      }
    }
  }

  .filter-type {
    font-size: functions.calc-pixel(15);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: functions.calc-pixel(5);
    @include mixins.breakpoint(medium) {
      margin-bottom: functions.calc-pixel(30);
    }
  }

  .filter-button-group {
    button {
      font-size: functions.calc-pixel(14);
      text-transform: uppercase;
      border-top: 1px solid variables.$gb_grey-blue;
      color: variables.$gb_grey-blue;
      margin-right: functions.calc-pixel(30);
      margin-bottom: functions.calc-pixel(30);
      padding-right: functions.calc-pixel(30);
      padding-top: functions.calc-pixel(15);

      &:hover:not([disabled]),
      &.active {
        border-top: 3px solid var(--gb-brand-primary-light);
        color: var(--gb-brand-primary-light);
        padding-top: functions.calc-pixel(13);
      }

      &:disabled {
        cursor: not-allowed;
        color: variables.$gb_grey_150;
        border-top: 1px solid variables.$gb_grey_150;
      }
    }
  }

  .gb-select {
    margin-bottom: 0;
  }
}

.collection-link-list-wrapper {
  margin-bottom: 3.75rem;
}

.collection-link-list-trigger {
  display: flex;
  justify-content: center;
  width: 100%;

  &__btn {
    background: variables.$gb_grey-blue;
    border: 0;
    color: variables.$gb_white;
    width: 100%;
    @include mixins.breakpoint(medium) {
      width: auto;
    }
  }
}

.collection-link-list {
  background: variables.$gb_grey-blue;

  > .grid-container {
    position: relative;
  }

  &__btn-close {
    position: absolute;
    top: 3.4375rem;
    right: functions.calc-pixel(10);
    @include mixins.breakpoint(medium) {
      right: functions.calc-pixel(15);
    }

    i.icon::before {
      color: variables.$gb_white;
    }
  }

  ul li b {
    font-weight: variables.$font-weight-regular;
  }

  h3 {
    @extend .grid-x !optional;
    @extend .large-12 !optional;
    color: variables.$gb_white;
    padding-top: 3.125rem;
    margin-bottom: functions.calc-pixel(40);
    padding-right: functions.calc-pixel(30);
  }

  ul {
    margin: 0;
    padding: 0 0 4rem 0;

    @include mixins.breakpoint(medium) {
      column-count: 2;
      column-gap: 1.25rem;
    }

    @include mixins.breakpoint(large) {
      column-count: 4;
    }

    li {
      overflow: hidden;
      display: inline-block;
      width: 100%;
      padding-bottom: 0;
      margin-bottom: 0.75rem;
      list-style: none;

      a {
        position: relative;
        color: variables.$gb_white;
        font-size: 0.8125rem;
        text-transform: uppercase;
        padding-left: 1.25rem;

        &::before {
          position: absolute;
          left: 0;
          top: 0.4375rem;
        }
      }
    }
  }

  &-subtitle {
    display: block;
    margin-bottom: 1.25rem;
    color: variables.$gb_white;
    font-size: 1.25rem;
    font-weight: variables.$font-weight-bold;
    text-transform: uppercase;
  }
}

.c-all-collection-content {
  position: relative;
}

.c-loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 5;

  .c-loader {
    margin: 0 auto;
    z-index: 6;
  }
}

.single-collection {
  margin-bottom: 5.625rem;

  .image--aspect-ratio {
    margin-bottom: functions.calc-pixel(30);
  }
}

.c-single-collection {
  $element: &;

  &--button {
    margin-bottom: functions.calc-pixel(75);
    margin-top: functions.calc-pixel(20);
    text-align: center;

    button {
      width: 100%;
      @include mixins.breakpoint(medium) {
        width: auto;
      }
    }

    @include mixins.breakpoint(medium) {
      margin-top: functions.calc-pixel(60);
    }
  }

  p {
    margin-bottom: 1.875rem;

    @include mixins.breakpoint(medium) {
      margin-bottom: 2.5rem;
    }

    @include mixins.breakpoint(large) {
      width: 66.6666%;
      margin-bottom: 2.1875rem;
    }
  }

  a {
    margin-bottom: 1.875rem;

    @include mixins.breakpoint(medium) {
      margin-bottom: 2.5rem;
    }

    @include mixins.breakpoint(large) {
      margin-bottom: 3.4375rem;
    }
  }

  h3 {
    margin-bottom: 1.875rem;
    overflow: hidden;
    max-height: 3.75rem;

    @include mixins.breakpoint(medium) {
      margin-bottom: 1.25rem;
    }

    @include mixins.breakpoint(large) {
      max-height: 5rem;
    }
  }

  &--row {
    @include mixins.breakpoint(medium) {
      margin-bottom: functions.calc-pixel(75);
    }

    @include mixins.breakpoint(large) {
      margin-bottom: functions.calc-pixel(80);
    }

    &:nth-of-type(even) {
      .column-1 {
        order: 2;
        text-align: left;
      }

      .column-2 {
        text-align: right;

        .c-single-collection--content p {
          @include mixins.breakpoint(large) {
            width: 100%;
            padding-left: 33.3333%;
          }
        }
      }
    }

    .first-picture {
      height: auto;
      @include mixins.breakpoint(medium) {
        width: 100%;
      }
    }

    .third-picture {
      height: auto;
      @include mixins.breakpoint(medium) {
        width: 66.6666%;
      }
    }

    .second-picture {
      height: auto;
      @include mixins.breakpoint(medium) {
        width: 83.3333%;
      }
    }

    .column-1 {
      text-align: right;
      @include mixins.breakpoint(medium) {
        margin-top: 10px;
      }
      @include mixins.breakpoint(large) {
        margin-top: 8px;
      }

      .third-picture {
        margin-top: functions.calc-pixel(25);
      }
    }

    .column-2 {
      text-align: left;
    }
  }

  &--title {
    @include mixins.word-wrap();
    font-weight: variables.$font-weight-bold;
    font-size: 0.9375rem;
    margin-bottom: 0;
    overflow: hidden;
    max-height: 2.1875rem;
  }

  &--content {
    #{$element}--title {
      margin-bottom: 0;
    }

    a::before {
      font-size: functions.calc-pixel(11);
    }

    &--title {
      font-weight: variables.$font-weight-bold;
      @include mixins.breakpoint(medium) {
        line-height: 2.6;
      }
      @include mixins.breakpoint(large) {
        line-height: 2;
      }
    }
  }
}
