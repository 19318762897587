@use '../base/functions';
@use '../base/mixins';
@use '../base/variables';

.c-downloadorder {
  text-align: left;

  form {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}

.c-downloadcenter {
  margin-bottom: 3.125rem;
  @include mixins.bullet-points-spacing();

  @include mixins.breakpoint(medium) {
    margin-bottom: 0;
  }

  .c-search-navigation {
    position: relative;

    & > form {
      margin: 0;
      padding: 0;
      border: none;

      & input {
        border: none;
        box-shadow: none;
        padding-left: 0;
      }
    }

    &.c-searchbar-only {
      margin-bottom: functions.calc-center(160, 45);
    }
  }

  .c-suggestion-flyout {
    background-color: variables.$gb_white;
    border-top: 1px solid variables.$gb_grey_150;
    box-shadow: 0 10px 1.5625rem variables.$gb_grey_150;
    position: absolute;
    transform: translate(0, 1.875rem);
    z-index: 21;
    width: 100%;
    top: 4.625rem;

    > div {
      display: block;
      cursor: pointer;

      & .highlighted {
        font-style: normal;
        font-weight: bold;
        font-family: variables.$body-font-family;
      }

      & [data-active='true'] {
        background-color: variables.$gb_grey_060;
      }
    }
  }
}
