@use '../base/mixins';
@use '../base/variables';

.tab-nav {
  &__headline {
    margin-bottom: 3.125rem;
    margin-top: 1.875rem;
  }
}

.tab-nav__tabs {
  color: variables.$gb_grey-blue;
  text-transform: uppercase;
  padding-left: 0;
  white-space: nowrap;
  cursor: pointer;
  list-style: none;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  border: none;
  background: white;
  padding-right: 1.5625rem;
  padding-top: 0;

  h3,
  span,
  .tab-nav__title {
    padding-top: 0.75rem;
    padding-right: 1.875rem;
    background: white;
    border-top: solid;
    border-width: 0.0625rem 0 0 0;
    border-color: variables.$gb_grey-blue;
    font-size: 100%;
    margin: 0;
    display: inline;
    font-weight: 400;
    letter-spacing: unset;

    &:hover {
      border-width: 0.125rem 0 0 0;
      border-color: var(--gb-brand-primary-light);
      color: var(--gb-brand-primary-light);
      background: white;
    }
  }

  span {
    :not(:has(span)) {
      padding-right: 0;
    }
  }

  &:focus {
    outline: none;
    outline: 0;
    background: white;
  }
}

.tab-nav__panel {
  border: none;
  padding-left: 0;
  padding-right: 0;
  margin-top: 1.625rem;

  @include mixins.breakpoint(large) {
    padding-top: 0;
  }

  section {
    padding: 0;
  }

  &.RRT__panel--hidden {
    margin-top: 0;
    padding-bottom: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }

  .tab-nav__title--invisible {
    visibility: hidden;
    height: 0;
    margin-bottom: 0;
  }
}

.RRT__container {
  padding-top: 1.125rem;
}

.RRT__tab--selected {
  h3,
  span,
  .tab-nav__title {
    border-top: solid;
    border-width: 0.125rem;
    border-color: var(--gb-brand-primary-light);
    color: var(--gb-brand-primary-light);
  }
}

.RRT__showmore {
  color: variables.$gb_grey-blue;
  text-transform: uppercase;
  padding-left: 0;
  white-space: nowrap;
  cursor: pointer;
  list-style: none;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  border: none;
  background: white;
  padding-top: 0;
  padding-bottom: 0;

  &:focus {
    outline: none;
    outline: 0;
  }

  .RRT__showmore-label {
    padding-top: 0.75rem;
    padding-left: 1.875rem;
    padding-right: 2.5em;
    background: white;
    border-top: solid;
    border-width: 0.0625rem 0 0 0;
    border-color: variables.$gb_grey-blue;
    display: inline;
    bottom: 0;

    &:hover {
      border-width: 0.125rem 0 0 0;
      border-color: var(--gb-brand-primary-light);
      color: var(--gb-brand-primary-light);
      background: white;
    }

    &:focus {
      outline: none;
      outline: 0;
    }

    &::before {
      @include mixins.web20-icon('chevron-large-down');
      position: absolute;
      right: 0.9375rem;
    }

    &--selected {
      border: none;
      color: var(--gb-brand-primary-light);

      &::before {
        @include mixins.web20-icon('chevron-large-up');
        position: absolute;
        right: 0.9375rem;
      }

      &:focus {
        outline: none;
        outline: 0;
      }
    }
  }
}

.RRT__showmore-list {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  background: variables.$gb_white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.22);
  border-radius: 2px;

  .tab-nav__tabs {
    text-align: right;
    padding-right: 0;
    padding-left: 0;

    h3,
    span,
    .tab-nav__title {
      border: none;
      padding-right: 0;
      padding-left: 0;
    }
  }

  &:focus {
    outline: none;
    outline: 0;
    background: white;
  }

  &--opened {
    padding-top: 0.9375rem;
    right: 0;
    top: 100%;
    padding-bottom: 0.9375rem;

    &:focus {
      outline: none;
      outline: 0;
    }
  }
}
