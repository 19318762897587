@use '../base/mixins';
@use '../base/variables';

.c-footer {
  border-top: 1px solid variables.$gb_grey_150;
  min-height: 13.75rem;
  padding: 5rem 0 6.25rem 0;
  position: relative;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint(medium) {
      flex-flow: row wrap;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__logo {
    margin-bottom: 2.5em;

    img {
      height: 1.3125rem;
      width: 9.75rem;
      vertical-align: middle;
    }

    @include mixins.breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  &__country-change {
    margin-bottom: 2.75rem;

    @include mixins.breakpoint(medium) {
      margin-bottom: 0;
    }

    p {
      font-size: 0.9375rem;

      strong {
        display: block;
        margin-bottom: 0.625rem;
        font-weight: variables.$font-weight-bold;
      }

      a {
        padding: 0 0 0.125rem;
        border-bottom: 1px solid var(--gb-brand-primary-light);
        text-decoration: none;
      }
    }
  }

  &__social {
    margin-bottom: 1.25rem;

    a {
      margin: 0 1.25rem 1.25rem 0;

      &::before {
        display: none;
      }
    }

    img {
      width: 1.5625rem;
      height: 1.5625rem;
      vertical-align: middle;
    }

    @include mixins.breakpoint(medium) {
      margin-bottom: 0;

      a {
        margin: 0 0 0 1.25rem;
      }
    }
  }

  &__nav {
    @include mixins.word-wrap();

    a,
    button,
    p {
      margin-bottom: 0.9375rem;
      display: block;
      color: variables.$gb_black;
      font-size: 1rem;

      &::before {
        display: none;
      }
    }

    a,
    button {
      &:hover {
        text-decoration: underline;
      }
    }

    a:last-child,
    button:last-child {
      margin-bottom: 0;
    }

    @include mixins.breakpoint(medium) {
      margin-top: 2.5rem;
      display: flex;
      flex-basis: 100%;
      flex-wrap: wrap;

      a,
      button,
      p {
        font-size: 0.8125rem;
      }

      a,
      button {
        margin-right: 1.25rem;
      }

      p {
        margin-right: 4.375rem;
      }
    }

    @include mixins.breakpoint(large) {
      justify-content: flex-end;

      a,
      button,
      p {
        margin: 0 0 0 1.25rem;
      }

      p {
        margin-right: 5.625rem;
      }
    }
  }
}

.back-to-top-anchor {
  right: 2rem;
  bottom: calc(-3rem + calc(var(--chat-height, 0px) + var(--chat-controls-height, 0px)));
  opacity: 0;
  position: fixed;
  z-index: 99;
  transition:
    transform 0.2s cubic-bezier(0.4, 0, 1, 1),
    opacity 0.2s cubic-bezier(0.4, 0, 1, 1);

  &.isStatic {
    position: absolute;
    bottom: unset;
    top: -4.625rem;
    transition: none;
    opacity: 1;
  }

  &.isFixed {
    opacity: 1;
    transform: translateY(-5rem);
  }
}
