@use 'sass:color';
@use '../base/variables';
@use 'styles/base/mixins';
@use '../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss' as datepicker;

$datepicker__border-color: variables.$gb_grey_200;
$datepicker__highlighted-color: #3dcc4a;
$datepicker__muted-color: variables.$gb_white;
$datepicker__text-color: #000;
$datepicker__header-color: #000;
$datepicker__navigation-disabled-color: color.adjust($datepicker__muted-color, $lightness: 10%);

$datepicker__border-radius: 0;

.react-datepicker-wrapper {
  display: block;

  input[type='text'] {
    padding-right: 3.125rem;
  }
}

.react-datepicker {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);

  &__input-container {
    display: block;

    &::after {
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 0.9375rem;
      transform: translateY(-50%);
      font-size: 1.375rem;
      color: variables.$gb_grey_400;
    }
  }

  &__navigation {
    &--previous span::before {
      margin-top: -1.1875rem;
      border-color: $datepicker__header-color;
      &:hover {
        border-color: variables.$gb_grey_400;
      }
    }
    &--next span::before {
      margin-top: -1.1875rem;
      border-color: $datepicker__header-color;
      &:hover {
        border-color: variables.$gb_grey_400;
      }
    }
  }

  &__header {
    border: 0;
  }

  &__custom-header {
    @include mixins.typography-body-1();
    font-weight: 700;
  }

  &__day-name {
    color: $datepicker__header-color;
  }

  &__day {
    &--outside-month {
      color: variables.$gb_grey_200;
    }
  }

  &__time-container {
    width: 6.25rem;
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding: 0;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  color: variables.$gb_white;
}

.react-datepicker-popper {
  z-index: 15;
}
