@use '../base/mixins';
@use '../base/variables';

.global_search {
  min-height: 18.75rem;
  position: relative;

  & .c-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & .placeholder {
    height: 100vh;
  }
}
//
// Result Page Layout
// -----------------------------
.c-result-page {
  &.animated-logo-space {
    margin-top: 3.5rem;
  }

  margin-bottom: 5rem;

  .page-headline {
    margin-bottom: 1.875rem;
    margin-top: 2.8125rem;
    font-weight: variables.$font-weight-bold;

    @include mixins.breakpoint(medium) {
      margin-top: auto;
      margin-bottom: 3.125rem;
    }
  }

  .inline {
    display: inline-flex;
  }

  & .RRT__container {
    margin-bottom: 1.25rem;
  }

  .c-search-navigation {
    position: relative;

    & > form {
      margin: 0;
      padding: 0;
      border: none;

      & input {
        border: none;
        box-shadow: none;
        padding-left: 0;
      }
    }
  }

  .c-search-bar:hover .c-suggestion-flyout {
    display: block;
  }

  .tab-nav {
    margin-left: -7px;
  }

  .page-infotext {
    color: variables.$gb_grey-blue;
    margin-bottom: 2.1875rem;

    @include mixins.breakpoint(medium) {
      margin-bottom: 1.5625rem;
    }
  }

  &__no-result {
    .page-infotext {
      margin-bottom: 2.1875rem;

      @include mixins.breakpoint(medium) {
        margin-bottom: 3.125rem;
      }
    }

    h4 {
      margin-bottom: 1.5625rem;
    }
  }
}

// Suggestion Flyout
// -----------------------------
.c-suggestion-flyout {
  > div {
    & [role='option'] {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }
  }
}

// Product Result Page
// -----------------------------
.c-product-result-wrapper {
  margin: 1.875rem 0 5.625rem 0;

  @include mixins.breakpoint(medium) {
    margin: 2.8125rem 0 5.625rem 0;
  }

  & .dropdown {
    margin-bottom: 2.1875rem;

    & .dropdown__trigger {
      border: 0;
      color: variables.$gb_grey_400;
      font-size: 0.9375rem;
      text-transform: uppercase;

      &:after {
        color: variables.$gb_black;
      }
    }

    &--active .dropdown__content {
      display: flex;
      flex-direction: column;
      padding-bottom: 1.875rem;

      & > button {
        color: var(--gb-brand-primary);
        font-size: 1rem;
        padding: 0.75rem 0 0.125rem 1.5rem;
        text-align: left;

        span.c-tpp-area {
          color: var(--gb-brand-primary);
          display: inline;
          padding: 0;
        }
      }
    }
  }

  .c-result-page__filter {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding-left: 2.1875rem;
      cursor: pointer;
    }
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;

    .c-filter__checkmark::before {
      content: '';
      position: absolute;
      display: none;
    }

    &:checked ~ .c-filter__checkmark {
      background-color: var(--gb-brand-primary);

      &::before {
        display: block;
      }
    }
  }

  & .c-product-tiles {
    margin: 0 0 5.625rem 0;
  }

  .c-filter__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5625rem;
    width: 1.5625rem;
    background-color: variables.$gb_white;
    border: 1px solid variables.$gb_grey_100;

    &::before {
      @include mixins.web20-icon('check');
      color: variables.$gb_white;
      font-size: 1.5625rem;
    }
  }

  .c-product-result-left {
    .link-list {
      margin-left: 1.25rem;

      & > li button {
        color: var(--gb-brand-primary);
        font-size: 0.875rem;

        &:hover,
        &:active {
          color: variables.$gb_black;
          text-decoration: none;
        }

        &.selected {
          color: variables.$gb_black;
        }
      }
    }

    button::before {
      @include mixins.web20-icon('arrow-link-light-right');
      font-size: 0.6875rem;
      padding: 0 9px 0 0;
      position: relative;
      top: -1px;
    }
  }

  .c-result-list {
    list-style-type: none;
    margin: 0 0 5.625rem 0;
    padding: 0;

    & em {
      font-weight: bold;
    }

    > li {
      display: block;
      margin: 0 0 1.5rem;

      @include mixins.breakpoint(large) {
        max-width: 38.75rem;
      }
    }

    > li a {
      color: var(--gb-brand-primary);
      font-size: 1.125rem;
    }

    > li .item-content {
      font-size: 0.9375rem;

      .url-preview {
        color: variables.$gb_saturated_green;
        display: block;
        font-size: 0.9375rem;
        line-height: 1.6;
      }

      .snippet {
        color: variables.$gb_black;
      }
    }

    a::before {
      display: none;
    }
  }

  .c-load-more-block {
    display: flex;
    align-items: center;

    & > button {
      @include mixins.breakpoint(large) {
        flex: 2;
      }
    }

    & > .buttonSpaceCover {
      flex: 2;
    }

    i {
      position: relative;
      top: -2px;

      &::before {
        font-size: 1.125rem;
      }
    }

    & > span {
      display: none;
      text-align: center;
      color: variables.$gb_grey-blue;
      font-size: 0.9375rem;

      @include mixins.breakpoint(large) {
        display: block;
        flex: 1;
      }
    }
  }

  .c-product-tile {
    a {
      border: 5px solid variables.$gb_grey_060;
    }
  }
}
