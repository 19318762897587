.c-youtube-video {
  height: 0;
  padding: 0 0 56.25%;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.c-youtube-video-link {
  background: center no-repeat;
  background-size: cover;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  img {
    height: 4.875rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 4.875rem;
    z-index: 5;
  }
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;

  .show-video & {
    display: block;
  }

  .c-lightbox__video-container & {
    display: block;
  }
}
