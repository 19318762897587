@use 'sass:map';
@use '../base/mixins';
@use '../base/variables';
@use '~foundation-sites/scss/foundation';

.c-tiles {
  $this: &;
  border-bottom: variables.$border-black;
  padding-top: 4.6875rem;
  padding-bottom: 4.375rem; // 90px -30px from box-shadow
  margin-bottom: 3.75rem;

  &--disable-border {
    border-bottom: none;
  }

  .c-image-gallery {
    border-bottom: none;
  }

  .title {
    &--white {
      color: variables.$gb_white;
    }
  }

  &__content {
    padding: 2rem 1.25rem 3.4375rem 1.25rem;

    &__link {
      color: variables.$gb_black;

      &:hover {
        text-decoration: none;
      }

      &::before {
        content: none;
      }
    }
  }

  &__column {
    margin-bottom: #{map.get(foundation.$grid-margin-gutters, medium)};

    & > .c-tpp-area {
      // ensure content creator handle
      height: 100%;
    }

    & > .c-tpp-area[data-preview-id='#st_tiles'] > div {
      height: 100%;
    }
  }

  &__image {
    display: block;
    width: 100%;
  }

  h2 {
    margin-bottom: 1.875rem;
  }

  h3,
  h5 {
    margin-bottom: 0.75rem;
  }

  p {
    margin-top: 0.75rem;
    margin-bottom: 1.375rem;
  }

  a {
    margin-left: 0;

    &::before {
      padding-right: 0.625rem;
      text-indent: 0;
    }
  }

  .link-arrow {
    padding-left: 0;

    &::before {
      @include mixins.web20-icon('arrow-link-light-right');
      font-size: 0.6875rem;
      padding: 0 9px 0 0;
      position: relative;
      top: -1px;
    }
  }

  &__headline {
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    color: variables.$gb-black;
  }

  &__link {
    position: relative;
    display: block;
    transition: box-shadow variables.$gb_transitionFast linear;

    h3,
    h5 {
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    h3,
    h4,
    p {
      color: variables.$gb-black;
    }

    &::before {
      content: none;
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;

      .link-arrow {
        color: var(--gb-brand-primary-light);
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;

          &::before {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__wrapper {
    background-color: variables.$gb_white;
    height: 100%;

    .c-tiles__content__link .link-arrow {
      &:hover {
        text-decoration: underline;
      }

      &:hover::before {
        text-decoration: none;
      }
    }

    a {
      &:hover {
        box-shadow: none;
      }
    }

    &--linked {
      #{$this}__link {
        height: 100%;
      }
    }

    // state
    &--linked,
    &--video {
      .video-play-button {
        border: none;
      }

      #{$this}__link {
        &:hover,
        &:focus {
          box-shadow: variables.$box-shadow-carousel-hover;
        }
      }
    }
  }

  // state
  // indicator for autofilled tile
  .c-tiles--autofilled {
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    background: #46446b;
    z-index: 1;

    &::before {
      @include mixins.web20-icon('autofilled');
      color: variables.$gb_white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// Base classes
@for $i from 1 through 5 {
  .line-clamp-#{$i} {
    @include mixins.line-clamp($i);
  }
}

@include mixins.line-clamps-for-breakpoint(768);
@include mixins.line-clamps-for-breakpoint(1024);
@include mixins.line-clamps-for-breakpoint(1440);
