@use 'styles/base/mixins';

.image--aspect-ratio {
  &.sixteen-nine {
    @include mixins.aspect-ratio(16, 9);
  }

  &.sixteen-seven {
    @include mixins.aspect-ratio(16, 7);
  }

  &.sixteen-six {
    @include mixins.aspect-ratio(16, 6);
  }

  &.one-one {
    @include mixins.aspect-ratio(1, 1);
  }

  &.two-one {
    @include mixins.aspect-ratio(2, 1);
  }

  &.eight-eleven {
    @include mixins.aspect-ratio(8, 11);
  }

  &.three-two {
    @include mixins.aspect-ratio(3, 2);
  }

  &.three-four {
    @include mixins.aspect-ratio(3, 4);
  }

  &.four-three {
    @include mixins.aspect-ratio(4, 3);
  }
}
