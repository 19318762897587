@use '../base/mixins';
@use '../base/variables';

$related-bg: variables.$gb_grey_700;
$related-color: variables.$gb_white;

.c-related-links {
  background: $related-bg;
  color: $related-color;
  padding-bottom: 6.25rem;
  padding-top: 4.6875rem;

  @include mixins.breakpoint(medium) {
    padding-bottom: 6.25rem;
    padding-top: 4.25rem;
  }

  @include mixins.breakpoint(large) {
    padding-bottom: 6.25rem;
    padding-top: 4.6875rem;
  }

  .link-list {
    margin-bottom: 0.625rem;
    margin-top: 0;

    li {
      font-size: 0.6875rem;
      padding-bottom: 5px;

      a {
        color: $related-color;
        font-size: 0.8125rem;
        line-height: 1.65;
      }
    }
  }

  &__block {
    @include mixins.column-break-inside(avoid);
    margin-bottom: 2.625rem;
    padding-right: 1.5625rem;
    width: 50%;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include mixins.breakpoint(large) {
      margin-bottom: 1rem;
    }
  }

  .list-block-column {
    @include mixins.breakpoint(medium) {
      @include mixins.content-columns(2);
    }
  }
}
