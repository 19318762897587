.nord {
  --spacing-0: 0;
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 1rem;
  --spacing-4: 1.5rem;
  --spacing-5: 2rem;
  --spacing-6: 4rem;
  --spacing-7: 8rem;
  --spacing-8: 16rem;
  --spacing-9: 32rem;

  --font-family-primary: 'AktivGrotesk', helvetica, arial, sans-serf;
  --font-family-monospace: monospace;

  --font-size-1: 2.875rem;
  --font-size-2: 2.625rem;
  --font-size-3: 2.25rem;
  --font-size-4: 2rem;
  --font-size-5: 1.75rem;
  --font-size-6: 1.625rem;
  --font-size-7: 1.5rem;
  --font-size-8: 1.375rem;
  --font-size-9: 1.25rem;
  --font-size-10: 1.125rem;
  --font-size-11: 1rem;
  --font-size-12: 0.875rem;
  --font-size-13: 0.8125rem;
  --font-size-14: 0.75rem;

  --line-height-1: 3.75rem;
  --line-height-2: 3.25rem;
  --line-height-3: 2.75rem;
  --line-height-4: 2.5rem;
  --line-height-5: 2.25rem;
  --line-height-6: 2rem;
  --line-height-7: 2rem;
  --line-height-8: 1.75rem;
  --line-height-9: 1.75rem;
  --line-height-10: 1.5rem;
  --line-height-11: 1.5rem;
  --line-height-12: 1.25rem;
  --line-height-13: 1rem;
  --line-height-14: 1rem;

  --cl17: #26513b;
  --cl18: #4b7155;
  --cl19: #6c906e;
  --cl01: #ffffff;
  --cl02: #fcfcfc;
  --cl03: #f7f7f7;
  --cl04: #f5f5f5;
  --cl05: #f1f1f1;
  --cl06: #e6e6e6;
  --cl07: #e3e3e3;
  --cl08: #d9d9d9;
  --cl09: #cccccc;
  --cl10: #b4b4b4;
  --cl11: #999999;
  --cl12: #707070;
  --cl13: #666666;
  --cl14: #5c5c5c;
  --cl15: #3d3d3d;
  --cl16: #000000;
  --cl20: #008800;
  --cl21: #f07800;
  --cl22: #ce0000;
  --cl23: #377b78;

  --cq01: #376eb4;
  --cq02: #9bb6d9;
  --cq03: #4ba5a0;
  --cq04: #a5d2cf;
  --cq05: #646468;
  --cq06: #b1b1b3;
  --cq07: #b4a078;
  --cq08: #d9cfbb;

  --cqdark01: #e6e6e6;
  --cqdark02: #bfd1dc;
  --cqdark03: #4ba5a0;
  --cqdark04: #388d95;
  --cqdark05: #92b0e6;
  --cqdark06: #6d95c9;
  --cqdark07: #b4a078;
  --cqdark08: #878977;

  --cd01: #376eb4;
  --cd02: #6c8ac1;
  --cd03: #97a8cd;
  --cd04: #bfc6da;
  --cd05: #e6e6e6;
  --cd06: #c2d6d4;
  --cd07: #a0c2c6;
  --cd08: #77b5b1;

  --cs01: #376eb4;
  --cs02: #517aba;
  --cs03: #6787bf;
  --cs04: #7b94c5;
  --cs05: #8ea1cb;
  --cs06: #a0aed0;
  --cs07: #b2bcd6;
  --cs08: #c3cadb;
  --cs09: #d5d8e1;
  --cs10: #e6e6e6;

  --csdark01: #f7f7f7;
  --csdark02: #e1e7ef;

  --grey03: var(--cl03);
  --grey04: var(--cl04);
  --grey05: var(--cl05);
  --grey06: var(--cl06);
  --grey07: var(--cl07);
  --grey08: var(--cl08);
  --grey09: var(--cl09);
  --grey10: var(--cl10);
  --grey11: var(--cl11);
  --grey12: var(--cl12);
  --grey13: var(--cl13);
  --grey14: var(--cl14);
  --grey15: var(--cl15);
  --black: var(--cl16);
  --success: var(--cl20);
  --warning: var(--cl21);
  --error: var(--cl22);
  --primary-light: var(--cl17);
  --primary: var(--cl18);
  --primary-dark: var(--cl19);
  --transparent-primary-light: 55, 110, 180;
  --transparent13: 102, 102, 102;
  --transparent15: 61, 61, 61;
  --transparent-white: 255, 255, 255;
  --transparent-black: 0, 0, 0;
  --transparent-success: 0, 136, 0;
  --transparent-warning: 240, 120, 0;
  --transparent-error: 206, 0, 0;
  --transparent: rgba(255, 255, 255, 0);
  --light-hover: rgba(0, 0, 0, 0.04);
  --dark-hover: rgba(0, 0, 0, 0.08);
  --black-hover: rgba(0, 0, 0, 0.12);

  --font-weight-hairline: 100;
  --font-weight-thin: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;
  --font-weight-body: 400;
  --font-weight-heading: 700;

  --letter-spacing-tighter: -0.05em;
  --letter-spacing-tight: -0.025em;
  --letter-spacing-normal: 0em;
  --letter-spacing-wide: 0.025em;
  --letter-spacing-wider: 0.05em;
  --letter-spacing-widest: 0.1em;

  --size-0: 0;
  --size-1: 0.25rem;
  --size-2: 0.5rem;
  --size-3: 0.75rem;
  --size-4: 1rem;
  --size-5: 1.25rem;
  --size-6: 1.5rem;
  --size-8: 2rem;
  --size-10: 2.5rem;
  --size-12: 3rem;
  --size-16: 4rem;
  --size-20: 5rem;
  --size-24: 6rem;
  --size-32: 8rem;
  --size-40: 10rem;
  --size-48: 12rem;
  --size-56: 14rem;
  --size-64: 16rem;
  --size-px: 1px;
  --size-xs: 20rem;
  --size-sm: 24rem;
  --size-md: 28rem;
  --size-lg: 32rem;
  --size-xl: 36rem;
  --size-2xl: 42rem;
  --size-3xl: 48rem;
  --size-4xl: 56rem;
  --size-5xl: 64rem;
  --size-6xl: 72rem;
  --size-7xl: 80rem;
  --size-prose: 65ch;
  --size-1-2: 50%;
  --size-1-3: 33.333333%;
  --size-2-3: 66.666667%;
  --size-1-4: 25%;
  --size-2-4: 50%;
  --size-3-4: 75%;
  --size-1-5: 20%;
  --size-2-5: 40%;
  --size-3-5: 60%;
  --size-4-5: 80%;
  --size-1-6: 16.666667%;
  --size-2-6: 33.333333%;
  --size-3-6: 50%;
  --size-4-6: 66.666667%;
  --size-5-6: 83.333333%;
  --size-1-12: 8.333333%;
  --size-2-12: 16.666667%;
  --size-3-12: 25%;
  --size-4-12: 33.333333%;
  --size-5-12: 41.666667%;
  --size-6-12: 50%;
  --size-7-12: 58.333333%;
  --size-8-12: 66.666667%;
  --size-9-12: 75%;
  --size-10-12: 83.333333%;
  --size-11-12: 91.666667%;
  --size-full: 100%;
  --size-screen-height: 100vh;
  --size-screen-width: 100vw;

  --border-width-0: 0;
  --border-width-2: 2px;
  --border-width-4: 4px;
  --border-width-8: 8px;
  --border-width-default: 1px;

  --border-style-solid: solid;
  --border-style-dashed: dashed;
  --border-style-dotted: dotted;

  --border-radius-none: 0;
  --border-radius-sm: 0.125rem;
  --border-radius-default: 0.25rem;
  --border-radius-md: 0.375rem;
  --border-radius-lg: 0.5rem;
  --border-radius-xl: 0.75rem;
  --border-radius-2xl: 1rem;
  --border-radius-3xl: 1.5rem;
  --border-radius-full: 9999px;

  --box-shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --box-shadow-default: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --box-shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --box-shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --box-shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --box-shadow-2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --box-shadow-inner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);

  --z-index-0: 0;
  --z-index-10: 10;
  --z-index-20: 20;
  --z-index-30: 30;
  --z-index-40: 40;
  --z-index-50: 50;
  --z-index-60: 60;
  --z-index-70: 70;
  --z-index-80: 80;
  --z-index-90: 90;
  --z-index-auto: auto;

  --transform-center: center;
  --transform-top: top;
  --transform-top-right: top right;
  --transform-right: right;
  --transform-bottom-right: bottom right;
  --transform-bottom: bottom;
  --transform-bottom-left: bottom left;
  --transform-left: left;
  --transform-top-left: top left;

  --translate-0: 0;
  --translate-1: 0.25rem;
  --translate-2: 0.5rem;
  --translate-3: 0.75rem;
  --translate-4: 1rem;
  --translate-5: 1.25rem;
  --translate-6: 1.5rem;
  --translate-8: 2rem;
  --translate-10: 2.5rem;
  --translate-12: 3rem;
  --translate-16: 4rem;
  --translate-20: 5rem;
  --translate-24: 6rem;
  --translate-32: 8rem;
  --translate-40: 10rem;
  --translate-48: 12rem;
  --translate-56: 14rem;
  --translate-64: 16rem;
  --translate-px: 1px;

  --translate-minus-full: -100%;
  --translate-minus-1-2: -50%;
  --translate-1-2: 50%;
  --translate-full: 100%;

  --scale-0: 0;
  --scale-50: 0.5;
  --scale-75: 0.75;
  --scale-90: 0.9;
  --scale-95: 0.95;
  --scale-100: 1;
  --scale-105: 1.05;
  --scale-110: 1.1;
  --scale-125: 1.25;
  --scale-150: 1.5;

  --rotate-0: 0;
  --rotate-45: 45deg;
  --rotate-90: 90deg;
  --rotate-180: 180deg;
  --rotate-minus-180: -180deg;
  --rotate-minus-90: -90deg;
  --rotate-minus-45: -45deg;

  --skew-0: 0;
  --skew-3: 3deg;
  --skew-6: 6deg;
  --skew-12: 12deg;
  --skew-minus-12: -12deg;
  --skew-minus-6: -6deg;
  --skew-minus-3: -3deg;

  --transition-none: none;
  --transition-all: all;
  --transition-default: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform;
  --transition-colors: background-color, border-color, color, fill, stroke;
  --transition-opacity: opacity;
  --transition-shadow: box-shadow;
  --transition-transform: transform;

  --timing-function-default: cubic-bezier(0.4, 0, 0.2, 1);
  --timing-function-linear: linear;
  --timing-function-in: cubic-bezier(0.4, 0, 1, 1);
  --timing-function-out: cubic-bezier(0, 0, 0.2, 1);
  --timing-function-in-out: cubic-bezier(0.4, 0, 0.2, 1);

  --timing-duration-75: 75ms;
  --timing-duration-100: 100ms;
  --timing-duration-150: 150ms;
  --timing-duration-200: 200ms;
  --timing-duration-300: 300ms;
  --timing-duration-500: 500ms;
  --timing-duration-700: 700ms;
  --timing-duration-1000: 1000ms;
  --timing-duration-default: 150ms;

  --h1-title-text-transform: uppercase;
  --h1-title-font-weight: var(--font-weight-normal);
  --h1-subtitle-text-transform: uppercase;
  --h1-subtitle-font-weight: var(--font-weight-normal);

  --h2-title-text-transform: initial;
  --h2-title-font-weight: var(--font-weight-normal);
  --h2-subtitle-text-transform: initial;
  --h2-subtitle-font-weight: var(--font-weight-normal);

  --h3-title-text-transform: initial;
  --h3-title-font-weight: var(--font-weight-normal);
  --h3-subtitle-text-transform: initial;
  --h3-subtitle-font-weight: var(--font-weight-normal);

  --h4-title-text-transform: initial;
  --h4-title-font-weight: var(--font-weight-bold);
  --h4-subtitle-text-transform: initial;
  --h4-subtitle-font-weight: var(--font-weight-light);

  --h5-title-text-transform: initial;
  --h5-title-font-weight: var(--font-weight-bold);
  --h5-subtitle-text-transform: initial;
  --h5-subtitle-font-weight: var(--font-weight-light);

  --h6-title-text-transform: initial;
  --h6-title-font-weight: var(--font-weight-bold);
  --h6-subtitle-text-transform: initial;
  --h6-subtitle-font-weight: var(--font-weight-light);
}
